import { Typography } from '@mui/material'

import CheckIcon from '../../assets/checklist-icons/check.svg'

import './CardLabel.scss'

export const CardLabel = ({
  label,
  color,
  onClick,
  selected
}: {
  label: string | null
  color: string | null
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  selected?: boolean
}) => {
  return (
    <button
      onClick={onClick}
      disabled={!onClick}
      className="card-label-main-div"
      style={{
        backgroundColor: color ?? '#CCCCCC'
      }}
    >
      <Typography variant="body1">
        {label?.length ? label : `No Label`}
      </Typography>
      {selected && <img alt="Edit" src={CheckIcon} />}
    </button>
  )
}
