import { Dispatch } from 'react'
import { Typography } from '@mui/material'

import { Button } from '../../../components/Button/Button'

import './WelcomeBack.scss'

export const WelcomeBack = ({
  setFillFromLocalStorage,
  handleOnClickJoinOlderRoom
}: {
  setFillFromLocalStorage: Dispatch<boolean>
  handleOnClickJoinOlderRoom: () => void
}) => {
  const handleOnClickNotUser = () => {
    localStorage.clear()
    setFillFromLocalStorage(false)
  }

  return (
    <div className="welcome-back-container">
      <div>
        <Typography variant="h4">
          {`Welcome back, `}
          <span id="muffin-text">{localStorage.getItem('name')}</span>
        </Typography>
        <button onClick={handleOnClickNotUser}>
          <Typography
            variant="subtitle1"
            style={{ textDecoration: 'underline' }}
          >
            {`Not ${localStorage.getItem('name')}?`}
          </Typography>
        </button>
      </div>
      {!!localStorage.getItem('roomName') && (
        <Button
          onClick={handleOnClickJoinOlderRoom}
          label={`Continue in ${localStorage.getItem('roomName')}`}
          fullWidth
        />
      )}
    </div>
  )
}
