import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { Avatar } from '../../../components/Avatar/Avatar'
import { Input } from '../../../components/Input/Input'
import { Button } from '../../../components/Button/Button'

import { useUserProfileContext } from '../../../hooks/context/UserProfileContext'
import { Validators } from '../../../utils/validators'

import { fetchResolve } from '../../../hooks/queries/helper'

import './ProfileTab.scss'

export const ProfileTab = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [about, setAbout] = useState<string>('')
  const {
    name: userCurrentName,
    email: userCurrentEmail,
    picURL: userCurrentPicURL,
    about: userCurrentAbout,
    dispatchUserProfile
  } = useUserProfileContext()

  // Update Theme-Color
  document
    .querySelector("meta[name='theme-color']")
    .setAttribute('content', '#fdeecb')

  useEffect(() => {
    setName(userCurrentName ?? '')
    setEmail(userCurrentEmail ?? '')
    setAbout(userCurrentAbout ?? '')
  }, [userCurrentName, userCurrentEmail, userCurrentPicURL, userCurrentAbout])

  const handleOnClickSave = () => {
    dispatchUserProfile({
      name,
      email,
      about,
      needsUpdate: true
    })
  }

  const onAvatarEdit = async (e) => {
    const file = e.target.files[0]

    if (!file) {
      return
    }
    if (file.size > 40000000) {
      toast.error('File should be smaller than 4MB')
      return
    }

    const uploadProfilePicture = async () => {
      const { url } = await fetchResolve('/cf-images-url')
      let formData = new FormData()
      formData.append('file', file)

      // Upload picture
      const res = await fetch(url, {
        method: 'POST',
        body: formData
      })
      const { result } = await res.json()

      const picURL = result.variants[0]
      dispatchUserProfile({
        picURL,
        needsUpdate: true
      })
    }

    toast.promise(uploadProfilePicture, {
      pending: 'Uploading Profile Picture...',
      success: 'Profile Picture Updated!',
      error: 'An error occurred! Try again!'
    })
  }

  return (
    <section className="profile-main-section">
      <Avatar
        name={userCurrentName}
        picURL={userCurrentPicURL}
        size={88}
        onEdit={onAvatarEdit}
      />
      <div className="input-container">
        <Input
          label="Your Name"
          placeholder="Nick e.g."
          value={name}
          setValue={setName}
          darker
          error={!Validators.name.test(name)}
        />
        <Input
          label="Email"
          placeholder="your@email.com"
          value={email}
          setValue={setEmail}
          darker
          error={!Validators.email.test(email)}
        />
        <Input
          label="About You"
          placeholder="Write information about you"
          value={about}
          setValue={setAbout}
          darker
          error={!Validators.about.test(about)}
        />
      </div>
      <Button
        disabled={
          (userCurrentName === name &&
            userCurrentEmail === email &&
            userCurrentAbout === about) ||
          !Validators.name.test(name) ||
          !Validators.email.test(email) ||
          !Validators.about.test(about)
        }
        label="Save"
        onClick={handleOnClickSave}
      />
    </section>
  )
}
