import { useMutation } from 'react-query'

import { postResolve, patchResolve, deleteResolve } from './helper'

import { ChecklistCardLabelType } from '../../types/ChecklistCard'

import { toast } from 'react-toastify'

export function useCreateChecklistCard() {
  return useMutation(
    async ({
      roomName,
      type,
      label,
      title,
      description,
      users,
      comment
    }: {
      roomName: string
      type: string
      label: ChecklistCardLabelType | null
      title: string
      description: string
      users: string[]
      comment: string
    }) =>
      postResolve(`/room/${roomName}/checklist`, {
        type,
        label,
        title,
        description,
        users,
        comment
      }),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        toast.error(error?.data?.error?.message ?? error.message)
      }
    }
  )
}

export function useDeleteChecklistCard() {
  return useMutation(
    async ({ roomName, cardID }: { roomName: string; cardID: string }) =>
      deleteResolve(`/room/${roomName}/checklist/card/${cardID}`),
    {
      onSuccess: () => {},
      onError: (error: any) => {
        toast.error(error?.data?.error?.message ?? error.message)
      }
    }
  )
}

export function useUpdateChecklistCard() {
  return useMutation(
    async ({
      roomName,
      cardID,
      type,
      index,
      users,
      label,
      title,
      description
    }: {
      roomName: string
      cardID: string
      type?: string
      index?: number
      users?: string[]
      label?: ChecklistCardLabelType | null
      title?: string
      description?: string
    }) =>
      patchResolve(`/room/${roomName}/checklist/card/${cardID}`, {
        type,
        index,
        users,
        label,
        title,
        description
      })
  )
}

export function useCreateChecklistLabel() {
  return useMutation(
    async ({
      roomName,
      name,
      color
    }: {
      roomName: string
      name: string
      color: string
    }) =>
      postResolve(`/room/${roomName}/checklist/label`, {
        name,
        color
      })
  )
}

export function useCreateChecklistComment() {
  return useMutation(
    async ({
      roomName,
      cardID,
      comment
    }: {
      roomName: string
      cardID: string
      comment: string
    }) =>
      postResolve(`/room/${roomName}/checklist/card/${cardID}/comment`, {
        comment
      })
  )
}
