import { Typography } from '@mui/material'

import HomeIcon from '../../assets/menu-icons/home.svg'
import TimerIcon from '../../assets/menu-icons/timer.svg'
import ProfileIcon from '../../assets/menu-icons/profile.svg'
import ChatIcon from '../../assets/menu-icons/chat.svg'
import ChecklistIcon from '../../assets/menu-icons/checklist.svg'
import AboutIcon from '../../assets/menu-icons/about.svg'
import NextIcon from '../../assets/menu-icons/next.svg'
import LogoutIcon from '../../assets/menu-icons/logout.svg'

import { useTimerContext, useMenuTab } from '../../hooks/context'

import './MenuMobile.scss'

const ItemMenuMobile = ({
  label,
  icon,
  onClick,
  disabled,
  counter
}: {
  label: string
  icon: any
  onClick: any
  disabled?: boolean
  counter?: number
}) => (
  <button
    onClick={() => onClick(label)}
    disabled={disabled}
    className="item-menu-mobile-main-container"
  >
    <div className="left">
      <img src={icon} height={24} width={24} />
      <Typography variant="h5">{label}</Typography>
    </div>
    <div className="right">
      {!!counter && <div className="notification-counter">{counter}</div>}
      <img src={NextIcon} height={24} width={24} />
    </div>
  </button>
)

export const MenuMobile = ({
  setIsOpenMobileMenu,
  handleLogout,
  messagesToRead
}: {
  setIsOpenMobileMenu: any
  handleLogout: any
  messagesToRead: number
}) => {
  const { timerState } = useTimerContext()
  const { dispatchMenuTab } = useMenuTab()

  const handleItemClick = (tab: string) => {
    setIsOpenMobileMenu(false)
    dispatchMenuTab({ tab })
  }

  return (
    <div className="menu-mobile-main-div">
      <div className="menu-mobile-buttons">
        <ItemMenuMobile
          label="Home"
          icon={HomeIcon}
          onClick={handleItemClick}
        />
        <ItemMenuMobile
          label="Timer"
          icon={TimerIcon}
          onClick={handleItemClick}
          disabled={timerState === 'none'}
        />
        <ItemMenuMobile
          label="Profile"
          icon={ProfileIcon}
          onClick={handleItemClick}
        />
        <ItemMenuMobile
          label="Chat"
          icon={ChatIcon}
          onClick={handleItemClick}
          counter={messagesToRead}
        />
        <ItemMenuMobile
          label="Checklist"
          icon={ChecklistIcon}
          onClick={handleItemClick}
        />
        <ItemMenuMobile
          label="About"
          icon={AboutIcon}
          onClick={handleItemClick}
        />
      </div>
      <button onClick={handleLogout} className="logout-button">
        <img src={LogoutIcon} height={24} width={24} />
        <Typography variant="h6">Log Out</Typography>
      </button>
    </div>
  )
}
