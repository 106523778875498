import { useState } from 'react'
import { Typography } from '@mui/material'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { ChecklistCard } from '../ChecklistCard/ChecklistCard'
import { CreateCard } from '../CreateCard/CreateCard'

import {
  ChecklistCardType,
  CardTypeToLabel
} from '../../../../types/ChecklistCard'

import './ChecklistSection.scss'

const AddCardButton = ({ onClick }: { onClick: Function }) => {
  return (
    <button onClick={() => onClick()} className="add-card-bttn">
      <Typography>Add a Card</Typography>
    </button>
  )
}

export const ChecklistSection = ({
  type,
  cards,
  swipeable
}: {
  type: string
  cards: ChecklistCardType[]
  swipeable?: boolean
}) => {
  const [createCardOpen, setCreateCardOpen] = useState<boolean>(false)

  const handleClickAdd = () => {
    setCreateCardOpen(true)
  }

  if (!swipeable) {
    return (
      <div className="checklist-section-main-div">
        <Typography className="section-title">
          {CardTypeToLabel[type]}
        </Typography>
        <div className="scrollable-div">
          <div className="cards-container">
            {cards.map((card) => (
              <ChecklistCard key={card.id} card={card} />
            ))}
          </div>
          <AddCardButton onClick={handleClickAdd} />
        </div>
        <CreateCard
          open={createCardOpen}
          setOpen={setCreateCardOpen}
          cardSection={type}
        />
      </div>
    )
  }

  return (
    <div className="checklist-section-main-div">
      <Typography className="section-title">{CardTypeToLabel[type]}</Typography>
      <Droppable droppableId={type}>
        {(provided) => (
          <div
            className="scrollable-div"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <div className="cards-container">
              {cards.map((card, index) => (
                <Draggable key={card.id} draggableId={card.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ChecklistCard card={card} />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
            <AddCardButton onClick={handleClickAdd} />
          </div>
        )}
      </Droppable>
      <CreateCard
        open={createCardOpen}
        setOpen={setCreateCardOpen}
        cardSection={type}
      />
    </div>
  )
}
