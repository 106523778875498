import { Checkbox, Typography } from '@mui/material'

import './CheckboxInput.scss'

export const CheckboxInput = ({
  checked,
  setChecked,
  text
}: {
  checked: boolean
  setChecked: Function
  text: string
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  return (
    <div className="checkbox-div">
      <Checkbox checked={checked} onChange={handleChange} />
      <Typography variant="body2">{text}</Typography>
    </div>
  )
}
