import { createContext, useContext, useReducer } from 'react'

type TAction = {
  state?: 'work' | 'pause-work' | 'break' | 'pause-break' | 'none'
  timeLeft?: number | null // Seconds
  workTime?: number | null // In min
  breakTime?: number | null // In min
  timestamp?: number
}
type TimerProviderProps = { children: React.ReactNode; options?: any }
interface IState {
  state: 'work' | 'pause-work' | 'break' | 'pause-break' | 'none'
  timeLeft: number | null // Seconds
  workTime: number | null // In min
  breakTime: number | null // In min
  timestamp: number | null
}
interface IContext extends IState {
  dispatchTimer: any
}

const TimerContext = createContext<IContext | undefined>(undefined)

const initialStateTimer = {
  state: 'none',
  timeLeft: null,
  workTime: null,
  breakTime: null,
  timestamp: null
}
function TimerReducer(state = initialStateTimer, action: TAction) {
  return { ...state, ...action }
}

function TimerProvider({ children, options = {} }: TimerProviderProps) {
  const [stateTimer, dispatchTimer] = useReducer(TimerReducer, {
    ...initialStateTimer,
    ...options
  })
  const value = { ...stateTimer, dispatchTimer }
  return (
    <TimerContext.Provider value={value as any}>
      {children}
    </TimerContext.Provider>
  )
}

function useTimerContext() {
  const context = useContext(TimerContext)
  if (context === undefined) {
    throw new Error(`useTimerContext must be used within a IDProvider`)
  }
  return context
}
export { initialStateTimer, TimerProvider, useTimerContext }
