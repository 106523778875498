export type ChecklistCardType = {
  id: string
  title: string
  description: string
  users: string[]
  comments: ChecklistCardCommentType[]
  label: ChecklistCardLabelType | null
  type: 'tasks' | 'inProgress' | 'done'
  createdAt: number
  lastUpdate: ChecklistUpdateType
}

export type ChecklistCardLabelType = {
  name: string
  color: string
}

export type ChecklistCardCommentType = {
  userId: string
  createdAt: number
  message: string
}

export type ChecklistUpdateType = {
  userId: string
  date: number
}

export const CardTypeToLabel = {
  tasks: 'Tasks',
  done: 'Done',
  inProgress: 'In Progress'
}
