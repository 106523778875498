import { Typography, CircularProgress } from '@mui/material'

import './Button.scss'

type ButtonProps = {
  label: string | React.ReactElement
  onClick?: any
  disabled?: boolean
  transparent?: boolean
  fullWidth?: boolean
  isNotActive?: boolean
  mediumFont?: boolean
  small?: boolean
  isLoading?: boolean
}

export const Button = ({
  label,
  onClick,
  disabled,
  transparent,
  fullWidth,
  isNotActive,
  mediumFont,
  small,
  isLoading
}: ButtonProps) => {
  return (
    <button
      disabled={!onClick || disabled || isLoading}
      onClick={onClick}
      className={
        'button-main-div' +
        (transparent ? ' transparent' : '') +
        (isNotActive ? ' not-active' : '') +
        (small ? ' small' : '')
      }
      style={{
        width: fullWidth ? '100%' : 120,
        fontWeight: mediumFont ? 500 : 400
      }}
    >
      <Typography>{label}</Typography>
      {isLoading && <CircularProgress size={16} />}
    </button>
  )
}
