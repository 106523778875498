import { useState, useEffect } from 'react'
import { Modal } from '@mui/material'

import { Input } from '../Input/Input'
import { Button } from '../Button/Button'

import { Validators } from '../../utils/validators'

import './AddAnotherSessionModal.scss'

export const AddAnotherSessionModal = ({
  open,
  setOpen,
  onClick
}: {
  open: boolean
  setOpen: any
  onClick: Function
}) => {
  const [value, setValue] = useState<string>('')

  const inputIsInvalid =
    !Validators.integer.test(value) || Number(value) > 120 || Number(value) <= 0

  const handleOnClick = () => {
    if (!inputIsInvalid) {
      onClick(Number(value))
      setOpen(false)
    }
  }

  useEffect(() => {
    setValue('')
  }, [open])

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="add-another-session-modal">
        <Input
          label="New Session (minutes)"
          placeholder="45 e.g."
          value={value}
          setValue={setValue}
          error={inputIsInvalid}
          onClickEnter={handleOnClick}
        />
        <Button
          disabled={inputIsInvalid}
          onClick={handleOnClick}
          label="Add Session"
        />
      </div>
    </Modal>
  )
}
