import { Typography } from '@mui/material'

import Logo from '../../assets/logo-lettering.svg'
import HomeIcon from '../../assets/menu-icons/home.svg'
import HomeSolidIcon from '../../assets/menu-icons/home-solid.svg'
import TimerIcon from '../../assets/menu-icons/timer.svg'
import TimerSolidIcon from '../../assets/menu-icons/timer-solid.svg'
import ProfileIcon from '../../assets/menu-icons/profile.svg'
import ProfileSolidIcon from '../../assets/menu-icons/profile-solid.svg'
import ChatIcon from '../../assets/menu-icons/chat.svg'
import ChatSolidIcon from '../../assets/menu-icons/chat-solid.svg'
import ChecklistIcon from '../../assets/menu-icons/checklist.svg'
import ChecklistSolidIcon from '../../assets/menu-icons/checklist-solid.svg'
import AboutIcon from '../../assets/menu-icons/about.svg'
import AboutSolidIcon from '../../assets/menu-icons/about-solid.svg'
import LogoutIcon from '../../assets/menu-icons/logout.svg'

import { useTimerContext, useMenuTab } from '../../hooks/context'

import './Menu.scss'

const ItemMenu = ({
  label,
  icon,
  iconSolid,
  disabled,
  counter
}: {
  label: string
  icon: any
  iconSolid: any
  disabled?: boolean
  counter?: number
}) => {
  const { tab, dispatchMenuTab } = useMenuTab()
  const isActive = tab === label
  return (
    <button
      onClick={() => dispatchMenuTab({ tab: label })}
      disabled={disabled}
      className={
        isActive
          ? 'item-menu-main-container active'
          : 'item-menu-main-container'
      }
    >
      <div className="item-menu-label">
        <img src={isActive ? iconSolid : icon} height={20} width={20} />
        <Typography variant="body1">{label}</Typography>
      </div>
      {!!counter && <div className="notification-counter">{counter}</div>}
    </button>
  )
}

export const Menu = ({
  handleLogout,
  messagesToRead
}: {
  handleLogout: any
  messagesToRead: number
}) => {
  const { timerState } = useTimerContext()

  return (
    <div className="menu-main-div">
      <div className="content">
        <div className="top-section">
          <img id="menu-logo" src={Logo} />
          <ItemMenu label="Home" icon={HomeIcon} iconSolid={HomeSolidIcon} />
          <ItemMenu
            label="Timer"
            icon={TimerIcon}
            iconSolid={TimerSolidIcon}
            disabled={timerState === 'none'}
          />
          <ItemMenu
            label="Profile"
            icon={ProfileIcon}
            iconSolid={ProfileSolidIcon}
          />
          <ItemMenu
            label="Chat"
            icon={ChatIcon}
            iconSolid={ChatSolidIcon}
            counter={messagesToRead}
          />
          <ItemMenu
            label="Checklist"
            icon={ChecklistIcon}
            iconSolid={ChecklistSolidIcon}
          />
          <ItemMenu label="About" icon={AboutIcon} iconSolid={AboutSolidIcon} />
        </div>
        <div className="bottom-section">
          <button onClick={handleLogout} className="logout-button">
            <img src={LogoutIcon} height={20} width={20} />
            <Typography variant="body1">Log Out</Typography>
          </button>
        </div>
      </div>
    </div>
  )
}
