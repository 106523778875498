import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Input } from '../../components/Input/Input'
import { CheckboxInput } from '../../components/CheckboxInput/CheckboxInput'
import { Button } from '../../components/Button/Button'
import { AboutAppModal } from '../CreateRoom/AboutAppModal'
import { WelcomeBack } from '../CreateRoom/WelcomeBack/WelcomeBack'

import Logo from '../../assets/logo.svg'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { joinRoomMutation } from '../../hooks/queries/room'

import { Validators } from '../../utils/validators'

import './JoinRoom.scss'

export const JoinRoom = ({ hidePassword }: { hidePassword?: boolean }) => {
  const navigate = useNavigate()
  const { roomName } = useParams()
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [roomPassword, setRoomPassword] = useState<string>('')
  const [allowNewsletter, setAllowNewsletter] = useState<boolean>(false)
  const [fillFromLocalStorage, setFillFromLocalStorage] =
    useState<boolean>(false)

  const useJoinRoomMutation = joinRoomMutation()
  const buttonDisabled =
    (!fillFromLocalStorage && !Validators.name.test(name)) ||
    (!fillFromLocalStorage && !Validators.email.test(email)) ||
    !Validators.roomPassword.test(roomPassword)

  useEffect(() => {
    // If name is stored on localStorage, we should prevent the user
    // from entering the same data over and over again
    setFillFromLocalStorage(!!localStorage.getItem('name'))
  }, [localStorage])

  //MODAL
  const [openAboutAppModal, setOpenAboutAppModal] = useState<boolean>(false)

  const handleOnClick = () => {
    if (!buttonDisabled)
      useJoinRoomMutation.mutate({
        name: fillFromLocalStorage ? localStorage.getItem('name') : name,
        email: fillFromLocalStorage ? localStorage.getItem('email') : email,
        roomPassword,
        roomName,
        allowNewsletter
      })
  }

  const handleOnClickJoinOlderRoom = () => {
    useJoinRoomMutation.mutate(
      {
        name: fillFromLocalStorage ? localStorage.getItem('name') : name,
        email: fillFromLocalStorage ? localStorage.getItem('email') : email,
        roomName: localStorage.getItem('roomName'),
        roomPassword,
        allowNewsletter
      },
      {
        onError: (error) => {
          // If the current room doesn't exist, we need to
          // redirect the user to the create room page
          if (error?.data?.error?.message === 'Room not found!') {
            navigate('/create')
          }
        }
      }
    )
  }

  // Update Theme-Color
  document
    .querySelector("meta[name='theme-color']")
    .setAttribute('content', '#fdeecb')

  return (
    <section className="join-room-main-section">
      <div className="content">
        <img alt="Muffin" src={Logo} height={200} width={200} />
        <div className="form-container">
          <header className="header">
            {fillFromLocalStorage ? (
              <WelcomeBack
                setFillFromLocalStorage={setFillFromLocalStorage}
                handleOnClickJoinOlderRoom={handleOnClickJoinOlderRoom}
              />
            ) : (
              <>
                <Typography variant="h4">
                  {`Welcome to `}
                  <span id="muffin-text">
                    Muff<span id="muffin-i">i</span>n
                  </span>
                </Typography>
                <Typography variant="subtitle1">
                  Timer for Remote Teams.
                </Typography>
              </>
            )}
          </header>
          <div>
            <Typography variant="h6">
              {fillFromLocalStorage
                ? `Or, join a new room...`
                : `Enter your name and email to join`}
            </Typography>
            <div className="inputs-container">
              {!fillFromLocalStorage && (
                <Input
                  label="Your Name*"
                  placeholder="Nick e.g."
                  value={name}
                  setValue={setName}
                  error={!Validators.name.test(name)}
                  onClickEnter={handleOnClick}
                />
              )}
              {!fillFromLocalStorage && (
                <Input
                  label="Email*"
                  placeholder="your@email.com"
                  value={email}
                  setValue={setEmail}
                  error={!Validators.email.test(email)}
                  onClickEnter={handleOnClick}
                />
              )}
              {!hidePassword && (
                <Input
                  label="Password for Room"
                  placeholder="Create Password"
                  value={roomPassword}
                  setValue={setRoomPassword}
                  error={!Validators.roomPassword.test(roomPassword)}
                  isPassword
                  onClickEnter={handleOnClick}
                />
              )}
              <CheckboxInput
                text="I'd like to sign up for Muffin's weekly useful psychology newsletter (optional)"
                setChecked={setAllowNewsletter}
                checked={allowNewsletter}
              />
            </div>
            <Button
              disabled={buttonDisabled}
              onClick={handleOnClick}
              label="Join Room"
            />
          </div>
        </div>
      </div>
      <button
        onClick={() => setOpenAboutAppModal(true)}
        className="about-app-button"
      >
        <InfoOutlinedIcon />
        About App
      </button>
      <AboutAppModal open={openAboutAppModal} setOpen={setOpenAboutAppModal} />
    </section>
  )
}
