import { useState } from 'react'
import { IconButton, Popper } from '@mui/material'
import Picker from 'emoji-picker-react'

import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'

import './AddEmojiButton.scss'

export const AddEmojiButton = ({
  onClickEmoji
}: {
  onClickEmoji: Function
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const onEmojiClick = (event, emojiObject) => {
    onClickEmoji(emojiObject.emoji)
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <>
      <IconButton onClick={handleClick} className="add-emoji-bttn mobile-hide">
        <SentimentSatisfiedAltIcon />
      </IconButton>
      <Popper
        sx={{ zIndex: 999 }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        disablePortal
        placement="top"
      >
        <Picker onEmojiClick={onEmojiClick} />
      </Popper>
    </>
  )
}
