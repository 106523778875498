import { useState } from 'react'
import { Popover, ClickAwayListener, Tooltip, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { Avatar } from '../../../components/Avatar/Avatar'

import { useRoomDetails } from '../../../hooks/context/RoomDetailsContext'
import { useTimerContext } from '../../../hooks/context/TimerContext'

import BlackShareIcon from '../../../assets/room-icons/share-black.svg'
import BlackLogoutIcon from '../../../assets/room-icons/logout-black.svg'
import WhiteShareIcon from '../../../assets/room-icons/share-white.svg'
import WhiteLogoutIcon from '../../../assets/room-icons/logout-white.svg'

import './RoomDetails.scss'

const SharePopover = ({
  anchorEl,
  setAnchorEl,
  handleClose
}: {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: any
  handleClose: Function
}) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={() => handleClose()}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
  >
    <div className="share-popover-main-div">
      <FacebookMessengerShareButton
        appId="677685282770003"
        url={window.location.href}
      >
        <FacebookMessengerIcon size={32} round />
      </FacebookMessengerShareButton>
      <WhatsappShareButton url={window.location.href}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <TwitterShareButton url={window.location.href}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <button
        className="copy-link-button"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href)
          setAnchorEl(null)
        }}
      >
        <ContentCopyIcon />
      </button>
    </div>
  </Popover>
)

export const RoomDetails = ({ handleLogout }: { handleLogout: any }) => {
  const { roomName } = useParams()
  const { users } = useRoomDetails()
  const { state: timerState } = useTimerContext()
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [sharePopoverAnchorEl, setSharePopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const isTimerRunning = !timerState.includes('pause')

  const handleAddUser = () => {
    setTooltipOpen(true)
    navigator.clipboard.writeText(window.location.href)
  }

  return (
    <>
      <div className="room-details-main-div">
        <div className="users-container">
          <div className="avatars-container">
            {users
              .filter((user) => user.online)
              .map((u, index) => (
                <Avatar
                  key={`room-details-user-${index}`}
                  name={u?.name ?? ''}
                  picURL={u?.picURL ?? ''}
                />
              ))}
          </div>
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip
              onClose={() => setTooltipOpen(false)}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              classes={{
                tooltip: 'add-user-tooltip'
              }}
              title={
                <div>
                  <p className="title">URL Copied</p>
                  <p className="subtitle">{window.location.href}</p>
                </div>
              }
              placement="top"
            >
              <button onClick={handleAddUser} className="add-user">
                <Typography variant="body1">+</Typography>
              </button>
            </Tooltip>
          </ClickAwayListener>
        </div>
        <div className="room-name-container">
          <Typography variant="body1">
            {roomName?.length ? roomName : 'No Room Name'}
          </Typography>
          <button
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              setSharePopoverAnchorEl(event.currentTarget)
            }
          >
            <img
              alt="Share"
              src={isTimerRunning ? BlackShareIcon : WhiteShareIcon}
              height={20}
              width={20}
            />
          </button>
          <button onClick={handleLogout}>
            <img
              alt="Logout"
              src={isTimerRunning ? BlackLogoutIcon : WhiteLogoutIcon}
              height={20}
              width={20}
            />
          </button>
        </div>
        <Typography variant="body1" className="invite-friends-text">
          Invite your friends by sharing the room link!
        </Typography>
      </div>
      <SharePopover
        anchorEl={sharePopoverAnchorEl}
        setAnchorEl={setSharePopoverAnchorEl}
        handleClose={() => setSharePopoverAnchorEl(null)}
      />
    </>
  )
}
