import { createContext, useContext, useReducer } from 'react'

type TAction = {
  id?: string
  name?: string
  email?: string
  picURL?: string
  about?: string
  needsUpdate?: boolean
}
type UserProfileProviderProps = { children: React.ReactNode; options?: any }
interface IState {
  id: string
  name: string
  email: string
  picURL: string
  about: string
  needsUpdate: boolean
}
interface IContext extends IState {
  dispatchUserProfile: any
}

const UserProfileContext = createContext<IContext | undefined>(undefined)

const initialStateUserProfile = {
  id: '',
  name: '',
  email: '',
  picURL: '',
  about: '',
  needsUpdate: false
}
function UserProfileReducer(state = initialStateUserProfile, action: TAction) {
  return { ...state, ...action }
}

function UserProfileProvider({
  children,
  options = {}
}: UserProfileProviderProps) {
  const [stateUserProfile, dispatchUserProfile] = useReducer(
    UserProfileReducer,
    {
      ...initialStateUserProfile,
      ...options
    }
  )
  const value = { ...stateUserProfile, dispatchUserProfile }
  return (
    <UserProfileContext.Provider value={value as any}>
      {children}
    </UserProfileContext.Provider>
  )
}

function useUserProfileContext() {
  const context = useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error(`useUserProfileContext must be used within a IDProvider`)
  }
  return context
}
export { initialStateUserProfile, UserProfileProvider, useUserProfileContext }
