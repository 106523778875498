export const Validators = {
  email: /\S+@\S+\.\S+/,
  roomPassword: /\S*/,
  roomName: /\S+/,
  name: /\S+/,
  about: /\S*/,

  // Checklist Card
  cardTitle: /^.{1,32}$/,
  cardDescription: /^(.|\n){0,252}$/,
  cardComment: /^(.|\n){0,128}$/,
  labelName: /^.{1,16}$/,
  labelColor: /^#\S{6}$/,

  integer: /^\+?(0|[1-9]\d*)$/
}
