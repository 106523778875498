import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Modal } from '@mui/material'

import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { AddUsersButton } from '../../../../components/AddUsersButton/AddUsersButton'
import { CardLabel } from '../../../../components/CardLabel/CardLabel'
import { AddLabelModal } from '../AddLabelModal/AddLabelModal'
import { ChangeCardTypePopover } from '../ChangeCardTypePopover/ChangeCardTypePopover'

import { useCreateChecklistCard } from '../../../../hooks/queries/checklist'

import { CardTypeToLabel } from '../../../../types/ChecklistCard'
import { ChecklistCardLabelType } from '../../../../types/ChecklistCard'

import { Validators } from '../../../../utils/validators'

import './CreateCard.scss'

type CreateCardProps = {
  open: boolean
  setOpen: any
  cardSection: string
}

export const CreateCard = ({ open, setOpen, cardSection }: CreateCardProps) => {
  const createChecklistCard = useCreateChecklistCard()

  const { roomName } = useParams()
  const [label, setLabel] = useState<ChecklistCardLabelType | null>(null)
  const [type, setType] = useState<string>(cardSection)
  const [title, setTitle] = useState<string>('')
  const [users, setUsers] = useState<string[]>([])
  const [comment, setComment] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [openAddLabel, setOpenAddLabel] = useState<boolean>(false)

  const [changeCardTypeAnchorEl, setChangeCardTypeAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const buttonDisabled =
    !Validators.cardTitle.test(title) ||
    !Validators.cardDescription.test(description) ||
    !Validators.cardComment.test(comment) ||
    !Validators.labelName.test(label?.name)

  const handleOnClickCreate = () => {
    if (!buttonDisabled)
      createChecklistCard.mutate(
        {
          roomName,
          type,
          label,
          title,
          description,
          comment,
          users
        },
        {
          onSuccess: () => {
            setOpen(false)
          }
        }
      )
  }

  const handleSelectLabel = (label: ChecklistCardLabelType) => {
    setLabel(label)
    setOpenAddLabel(false)
  }

  // Force reset to iniial state
  useEffect(() => {
    setTitle('')
    setDescription('')
    setLabel(null)
    setComment('')
    setUsers([])
  }, [open])

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className="create-card-main-div">
          <Typography className="header" variant="h6">
            In list
            <button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setChangeCardTypeAnchorEl(event.currentTarget)
              }
            >
              <Typography variant="h6">{CardTypeToLabel[type]}</Typography>
            </button>
          </Typography>
          <CardLabel
            label={label?.name}
            color={label?.color}
            onClick={() => setOpenAddLabel(true)}
          />
          <Input
            label="Title*"
            placeholder="Enter New Title"
            value={title}
            setValue={setTitle}
            error={!Validators.cardTitle.test(title)}
            darker
            onClickEnter={handleOnClickCreate}
            allowEmojis
          />
          <Input
            label="Description"
            placeholder="Add Text"
            value={description}
            setValue={setDescription}
            error={!Validators.cardDescription.test(description)}
            darker
            multiline
            allowEmojis
          />
          <AddUsersButton userIds={users} setUserIds={setUsers} />
          <Input
            label="Comments"
            placeholder="Write first comment..."
            value={comment}
            setValue={setComment}
            error={!Validators.cardComment.test(comment)}
            darker
            onClickEnter={handleOnClickCreate}
            allowEmojis
          />
          <div className="bttn-container">
            <Button
              disabled={buttonDisabled}
              label="Create"
              onClick={handleOnClickCreate}
              isLoading={createChecklistCard.isLoading}
            />
          </div>
        </div>
      </Modal>
      <ChangeCardTypePopover
        anchorEl={changeCardTypeAnchorEl}
        handleClose={() => setChangeCardTypeAnchorEl(null)}
        type={type}
        setType={(type: string) => {
          setType(type)
          setChangeCardTypeAnchorEl(null)
        }}
      />
      <AddLabelModal
        open={openAddLabel}
        setOpen={setOpenAddLabel}
        onClickAdd={handleSelectLabel}
      />
    </>
  )
}
