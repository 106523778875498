import {
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  Popover
} from '@mui/material'

import { useRoomDetails } from '../../../../hooks/context/RoomDetailsContext'

export const AddUserPopover = ({
  anchorEl,
  handleClose,
  checked,
  setChecked
}: {
  anchorEl: HTMLButtonElement | null
  handleClose: any
  checked: string[]
  setChecked: Function
}) => {
  const { users } = useRoomDetails()

  const handleToggle = (userID: string) => {
    const currentIndex = checked.indexOf(userID)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(userID)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <List
        sx={{
          width: '100%',
          maxHeight: '90%',
          overflowY: 'auto'
        }}
      >
        {users.map((u) => {
          const labelId = `add-user-list-label-${u.id}`
          return (
            <ListItem key={`add-user-item-${u.id}`} disablePadding>
              <ListItemButton onClick={() => handleToggle(u.id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(u.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={u.name} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    </Popover>
  )
}
