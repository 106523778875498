import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Typography } from '@mui/material'

import { CardLabel } from '../../../../components/CardLabel/CardLabel'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'

import { useChecklist } from '../../../../hooks/context'
import { useCreateChecklistLabel } from '../../../../hooks/queries/checklist'

import AddButtonIcon from '../../../../assets/checklist-icons/add-bttn.svg'
import CloseButtonIcon from '../../../../assets/checklist-icons/close.svg'

import { ChecklistCardLabelType } from '../../../../types/ChecklistCard'

import './AddLabelModal.scss'

const newLabelColors: ChecklistCardLabelType[] = [
  { name: 'Yellow', color: '#F0D42F' },
  { name: 'Orange', color: '#FCAA55' },
  { name: 'Red', color: '#E95B4C' },
  { name: 'Blue', color: '#127ABC' },
  { name: 'Grey', color: '#828282' }
]

const CreateLabelTab = ({ onSuccess }: { onSuccess: Function }) => {
  const { roomName } = useParams()
  const [name, setName] = useState<string>('')
  const [color, setColor] = useState<string>('')
  const createChecklistLabel = useCreateChecklistLabel()

  const buttonDisabled = !name.length || !color.length

  const handleOnClick = () => {
    if (!buttonDisabled) {
      createChecklistLabel.mutate(
        {
          roomName,
          name,
          color
        },
        {
          onSuccess: () => onSuccess()
        }
      )
    }
  }

  return (
    <div className="content">
      <Input
        label="Name"
        placeholder="New Label Name"
        value={name}
        setValue={setName}
        error={!name.length}
        onClickEnter={handleOnClick}
        darker
      />
      <div className="choose-color">
        <Typography className="text" variant="subtitle1">
          Choose color
        </Typography>
        {newLabelColors.map((n, index) => (
          <CardLabel
            key={`new-label-${index}`}
            onClick={() => setColor(n.color)}
            label={n.name}
            color={n.color}
            selected={color === n.color}
          />
        ))}
      </div>
      <div className="create-new-label-bttn">
        <Button
          label="Add"
          disabled={buttonDisabled}
          onClick={handleOnClick}
          isLoading={createChecklistLabel.isLoading}
        />
      </div>
    </div>
  )
}

const SelectLabelTab = ({
  onClickAdd,
  setTab,
  isLoading
}: {
  onClickAdd: Function
  setTab: Function
  isLoading?: boolean
}) => {
  const [selected, setSelected] = useState<ChecklistCardLabelType>(null)
  const { labels } = useChecklist()

  return (
    <div className="content">
      <Typography className="add-label-header-text" variant="subtitle1">
        Choose label or create new
      </Typography>
      <div className="labels-container">
        {labels.map((l) => (
          <CardLabel
            key={`label-${l.name}`}
            label={l.name}
            color={l.color}
            onClick={() => setSelected(l)}
            selected={selected === l}
          />
        ))}
      </div>
      <button onClick={() => setTab('create')} className="add-label-bttn">
        <img alt="Add" src={AddButtonIcon} />
        <Typography variant="subtitle1">Add New Label</Typography>
      </button>
      <div className="add-bttn">
        <Button
          label="Add"
          disabled={selected === null}
          onClick={() => onClickAdd(selected)}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export const AddLabelModal = ({
  open,
  setOpen,
  onClickAdd,
  createOnly,
  isLoading
}: {
  open: boolean
  setOpen: Function
  onClickAdd: Function
  createOnly?: boolean
  isLoading?: boolean
}) => {
  const [tab, setTab] = useState<'select' | 'create'>(
    createOnly ? 'create' : 'select'
  )

  const onCreateLabelSuccess = () => {
    if (createOnly) setOpen(false)
    setTab('select')
  }

  useEffect(() => {
    if (open) setTab(createOnly ? 'create' : 'select')
  }, [open])

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="add-label-main-div">
        <div className="header">
          {tab === 'create' && !createOnly && (
            <button className="back-bttn" onClick={() => setTab('select')}>
              <Typography variant="subtitle1">Back</Typography>
            </button>
          )}
          <Typography variant="h6">Add Label</Typography>
          <button className="close-bttn" onClick={() => setOpen(false)}>
            <img alt="Close" src={CloseButtonIcon} />
          </button>
        </div>
        {tab === 'select' && (
          <SelectLabelTab
            onClickAdd={onClickAdd}
            setTab={setTab}
            isLoading={isLoading}
          />
        )}
        {tab === 'create' && (
          <CreateLabelTab onSuccess={onCreateLabelSuccess} />
        )}
      </div>
    </Modal>
  )
}
