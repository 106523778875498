import { useEffect, useState } from 'react'
import { Modal, Typography, IconButton } from '@mui/material'
import { useTimer } from 'react-timer-hook'
import useSound from 'use-sound'

import { Button } from '../../../../components/Button/Button'

import { useTimerContext } from '../../../../hooks/context/TimerContext'

import { MuffinAnimation } from '../../../../assets/timer-muffins'
import Icon2048 from '../../../../assets/games-icons/2048.svg'
import FlappyBirdIcon from '../../../../assets/games-icons/flappy-bird.png'
import TetrisIcon from '../../../../assets/games-icons/tetris.png'
import CloseIcon from '@mui/icons-material/Close'
import timerSfx from '../../../../assets/sounds/timer.wav'

import './Timer.scss'

const MAX_TIMESTAMP = 8640000000000000

const WorkSection = ({
  secondsLeft,
  workTimeSeconds
}: {
  secondsLeft: number
  workTimeSeconds: number
}) => {
  // Update Page Title
  document.title = 'Muffin - Work'

  return (
    <div className="work-section-div">
      <Typography variant="h4">Work Time</Typography>
      <img
        id="muffin-ilustration"
        src={
          MuffinAnimation[
            Math.floor(
              ((workTimeSeconds - secondsLeft) / workTimeSeconds) *
                MuffinAnimation.length
            )
          ]
        }
      />
    </div>
  )
}

const GameCard = ({
  icon,
  label,
  link,
  minutes,
  seconds
}: {
  icon: any
  label: string
  link: string
  minutes: number
  seconds: number
}) => {
  const { state: timerState } = useTimerContext()
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className="game-card-div">
        <img id="game-card-logo" src={icon} />
        <div>
          <Typography variant="h6">{label}</Typography>
          <button onClick={() => setOpen(true)}>Play</button>
        </div>
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div
          className={`game-modal-main-div game-${label
            .toLowerCase()
            .replace(/ /g, '-')} ${
            timerState.includes('pause') ? 'pause' : ''
          }`}
        >
          <div className="game-modal-header">
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="aspect-ratio">
            <iframe src={link} frameBorder="0" />
          </div>
          <Typography className="timer-value" variant="h4">
            {`${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')}`}
          </Typography>
        </div>
      </Modal>
    </>
  )
}

const BreakSection = ({
  minutes,
  seconds
}: {
  minutes: number
  seconds: number
}) => {
  // Update Page Title
  document.title = 'Muffin - Break'

  return (
    <div className="break-section-div">
      <Typography variant="h4">Break Time</Typography>
      <Typography id="timer-subtitle" variant="subtitle1">
        Play a game in the meantime
      </Typography>
      <div className="game-cards-container">
        <GameCard
          label="2048"
          icon={Icon2048}
          link="https://2048.letsmuffin.com"
          minutes={minutes}
          seconds={seconds}
        />
        <GameCard
          label="Tetris"
          icon={TetrisIcon}
          link="https://tetris.letsmuffin.com"
          minutes={minutes}
          seconds={seconds}
        />
        <GameCard
          label="Flappy Bird"
          icon={FlappyBirdIcon}
          link="https://flappy-bird.letsmuffin.com"
          minutes={minutes}
          seconds={seconds}
        />
      </div>
    </div>
  )
}

export const Timer = ({
  handleUpdateTimerState
}: {
  handleUpdateTimerState: Function
}) => {
  const {
    state: timerState,
    timeLeft,
    workTime,
    breakTime,
    timestamp,
    dispatchTimer
  } = useTimerContext()
  const { days, hours, minutes, seconds, restart, pause, isRunning } = useTimer(
    {
      expiryTimestamp: new Date(MAX_TIMESTAMP),
      onExpire: () => null
    }
  )
  const [playSound] = useSound(timerSfx)

  useEffect(() => {
    if (timerState === 'pause-work' || timerState === 'pause-break') {
      const time = new Date()
      time.setSeconds(time.getSeconds() + timeLeft)

      restart(time)
      pause()
    } else if (timerState === 'work' || timerState === 'break') {
      const time = new Date()
      time.setSeconds(time.getSeconds() + timeLeft)

      restart(time, true)
    }
  }, [timerState, timeLeft, timestamp])

  useEffect(() => {
    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      handleExpire()
    }
  }, [days, hours, minutes, seconds])

  const handlePause = () => {
    handleUpdateTimerState('pause')
  }

  const handleResume = () => {
    handleUpdateTimerState('resume')
  }

  const handleReset = () => {
    handleUpdateTimerState('reset')
  }

  const handleExpire = () => {
    playSound()
    dispatchTimer({
      state: timerState === 'work' ? 'break' : 'work',
      timeLeft: timerState === 'work' ? breakTime * 60 : workTime * 60
    })
  }

  const handleTakeBreak = () => {
    handleUpdateTimerState('next')
  }

  const handleBackToWork = () => {
    handleUpdateTimerState('next')
  }

  return (
    <div className="main-timer-div">
      {timerState.includes('work') && (
        <WorkSection
          secondsLeft={hours * 3600 + minutes * 60 + seconds}
          workTimeSeconds={workTime * 60}
        />
      )}
      {timerState.includes('break') && (
        <BreakSection minutes={minutes} seconds={seconds} />
      )}
      <Typography className="timer-value" variant="h2">
        {`${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`}
      </Typography>
      <div className="button-container">
        <div className="row">
          {!isRunning && <Button label="Resume" onClick={handleResume} />}
          {isRunning && <Button label="Pause" onClick={handlePause} />}
          <Button label="Reset" onClick={handleReset} transparent />
        </div>
        {timerState.includes('work') && (
          <button className="take-break-bttn" onClick={handleTakeBreak}>
            <Typography variant="body2">Take a Break</Typography>
          </button>
        )}
        {timerState.includes('break') && (
          <button className="take-break-bttn" onClick={handleBackToWork}>
            <Typography variant="body2">Back to Work</Typography>
          </button>
        )}
      </div>
    </div>
  )
}
