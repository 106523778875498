import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'
import { toast } from 'react-toastify'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import { ChecklistSection } from './ChecklistSection/ChecklistSection'

import { useChecklist } from '../../../hooks/context'
import { useUpdateChecklistCard } from '../../../hooks/queries/checklist'
import { useWindowDimensions } from '../../../hooks/useWindowDimensionsHook'

// Import Swiper styles
import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/scss/navigation'

import './ChecklistTab.scss'

export const ChecklistTab = () => {
  const { roomName } = useParams()
  const { tasks: t, inProgress: iP, done: d } = useChecklist()
  const { width } = useWindowDimensions()
  const [tasks, setTasks] = useState(t)
  const [inProgress, setInProgress] = useState(iP)
  const [done, setDone] = useState(d)
  const updateChecklistCard = useUpdateChecklistCard()

  // Update Theme-Color
  document
    .querySelector("meta[name='theme-color']")
    .setAttribute('content', '#fdeecb')

  const handleOnDragEnd = (result) => {
    if (!result.destination) return

    const cardID = result.draggableId
    let foundCard
    // Delete old record
    if (result.source.droppableId === 'tasks') {
      foundCard = tasks.find((c) => c.id === cardID)
    } else if (result.source.droppableId === 'inProgress') {
      foundCard = inProgress.find((c) => c.id === cardID)
    } else if (result.source.droppableId === 'done') {
      foundCard = done.find((c) => c.id === cardID)
    }

    const newTasks = tasks.filter((c) => c.id !== cardID)
    const newInProgress = inProgress.filter((c) => c.id !== cardID)
    const newDone = done.filter((c) => c.id !== cardID)

    foundCard.type = result.destination.droppableId
    if (result.destination.droppableId === 'tasks') {
      newTasks.splice(result.destination.index, 0, foundCard)
    } else if (result.destination.droppableId === 'inProgress') {
      newInProgress.splice(result.destination.index, 0, foundCard)
    } else if (result.destination.droppableId === 'done') {
      newDone.splice(result.destination.index, 0, foundCard)
    }

    setTasks(newTasks)
    setInProgress(newInProgress)
    setDone(newDone)

    updateChecklistCard.mutate(
      {
        roomName,
        cardID,
        type: result.destination.droppableId,
        index: result.destination.index
      },
      {
        onSuccess: () => {},
        onError: (error: any) => {
          toast.error(error?.data?.error?.message ?? error.message)
          setTasks(t)
          setInProgress(iP)
          setDone(d)
        }
      }
    )
  }

  // we need to store state locally other DnD won't work
  useEffect(() => {
    setTasks(t)
    setInProgress(iP)
    setDone(d)
  }, [t, iP, d])

  if (width < 1350) {
    return (
      <div className="checklist-main-div">
        <Swiper
          modules={[Pagination]}
          spaceBetween={16}
          slidesPerView={1}
          pagination={{
            clickable: true
          }}
          centeredSlides
        >
          <SwiperSlide>
            <ChecklistSection type="tasks" cards={tasks} />
          </SwiperSlide>
          <SwiperSlide>
            <ChecklistSection type="inProgress" cards={inProgress} />
          </SwiperSlide>
          <SwiperSlide>
            <ChecklistSection type="done" cards={done} />
          </SwiperSlide>
        </Swiper>
      </div>
    )
  }

  return (
    <div className="checklist-main-div">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <ChecklistSection type="tasks" cards={tasks} swipeable />
        <ChecklistSection type="inProgress" cards={inProgress} swipeable />
        <ChecklistSection type="done" cards={done} swipeable />
      </DragDropContext>
    </div>
  )
}
