import { useState } from 'react'
import { Typography } from '@mui/material'

import { Button } from '../../../../components/Button/Button'
import { AddAnotherSessionModal } from '../../../../components/AddAnotherSessionModal/AddAnotherSessionModal'

// ICONS
import AddButtonIcon from '../../../../assets/checklist-icons/add-bttn.svg'

import './ChooseTimer.scss'

const workTimeOptions = [5, 10, 15, 25, 55]
const breakTimeOptions = [5, 10, 15, 25, 30]

const AnotherTime = ({ onClick }: { onClick: Function }) => {
  return (
    <button onClick={() => onClick()} className="another-time-bttn">
      <img alt="Add" src={AddButtonIcon} />
      <Typography variant="body1">Other Time</Typography>
    </button>
  )
}

export const ChooseTimer = ({
  handleUpdateTimer
}: {
  handleUpdateTimer: Function
}) => {
  const [workTime, setWorkTime] = useState<null | number>(null)
  const [breakTime, setBreakTime] = useState<null | number>(null)
  const [workAnotherSessionOpen, setWorkAnotherSessionOpen] = useState(false)
  const [breakAnotherSessionOpen, setBreakAnotherSessionOpen] = useState(false)

  const handleClickWorkTime = (time: number) => {
    setWorkTime(time)
  }

  const handleClickBreakTime = (time: number) => {
    setBreakTime(time)
  }

  const handleClickStart = () => {
    handleUpdateTimer(workTime, breakTime)
  }

  const valuesToRenderHelper = (array: number[], selectedValue: number) => {
    const newArray = [
      ...array,
      !array.includes(selectedValue) ? selectedValue : null
    ]
      .filter((v) => !!v)
      .sort((a, b) => a - b)

    if (newArray.length <= 5) {
      return newArray
    }

    if (newArray.slice(0, 5).includes(selectedValue)) {
      return newArray.slice(0, 5)
    }
    return newArray.slice(1, 6)
  }

  return (
    <div className="choose-timer-main-div">
      <Typography className="header-text" variant="h4">
        Choose a schedule to start your session
      </Typography>
      <div className="flex-row">
        <div className="time-container">
          <Typography variant="h6">Work Time</Typography>
          {valuesToRenderHelper(workTimeOptions, workTime).map(
            (elem, index) => (
              <button
                key={`work-time-${index}`}
                className={`choose-time-bttn button-${index} ${
                  workTime === elem ? 'active' : ''
                }`}
                onClick={() => handleClickWorkTime(elem)}
              >
                <Typography variant="body1">{elem}</Typography>
              </button>
            )
          )}
          <AnotherTime onClick={() => setWorkAnotherSessionOpen(true)} />
        </div>
        <div className="time-container">
          <Typography variant="h6">Break Time</Typography>
          {valuesToRenderHelper(breakTimeOptions, breakTime).map(
            (elem, index) => (
              <button
                key={`break-time-${index}`}
                className={`choose-time-bttn button-${index} ${
                  breakTime === elem ? 'active' : ''
                }`}
                onClick={() => handleClickBreakTime(elem)}
              >
                <Typography variant="body1">{elem}</Typography>
              </button>
            )
          )}
          <AnotherTime onClick={() => setBreakAnotherSessionOpen(true)} />
        </div>
      </div>
      <Button
        label="Start"
        disabled={!workTime || !breakTime}
        onClick={handleClickStart}
      />
      <AddAnotherSessionModal
        open={workAnotherSessionOpen}
        setOpen={setWorkAnotherSessionOpen}
        onClick={(value: number) => setWorkTime(value)}
      />
      <AddAnotherSessionModal
        open={breakAnotherSessionOpen}
        setOpen={setBreakAnotherSessionOpen}
        onClick={(value: number) => setBreakTime(value)}
      />
    </div>
  )
}
