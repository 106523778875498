import { InputLabel, InputBase, FormControl } from '@mui/material'

import { AddEmojiButton } from '../AddEmojiButton/AddEmojiButton'

import './Input.scss'

type InputProps = {
  label: string
  placeholder?: string
  value: string
  setValue: any
  onClickEnter?: any
  isPassword?: boolean
  darker?: boolean
  error?: boolean
  hideLabel?: boolean
  multiline?: boolean
  allowEmojis?: boolean
}

export const Input = ({
  label,
  placeholder,
  value,
  setValue,
  onClickEnter,
  isPassword,
  darker,
  error = false,
  hideLabel,
  multiline,
  allowEmojis
}: InputProps) => {
  return (
    <div className={darker ? 'main-div-input darker' : 'main-div-input'}>
      <FormControl
        error={value.length !== 0 && error}
        variant="standard"
        fullWidth
      >
        {!hideLabel && (
          <InputLabel
            id={`input-label-${label.replace(/ /g, '-').toLowerCase()}`}
            shrink
          >
            {label}
          </InputLabel>
        )}
        <InputBase
          aria-labelledby={`input-label-${label
            .replace(/ /g, '-')
            .toLowerCase()}`}
          type={!isPassword ? 'text' : 'password'}
          placeholder={placeholder ?? ''}
          inputProps={{
            'data-testid': `content-input-${label
              .replace(/ /g, '-')
              .toLowerCase()}`
          }}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          fullWidth
          onKeyPress={(ev) => {
            if (ev.key === 'Enter' && onClickEnter) {
              onClickEnter()
            }
          }}
          multiline={multiline}
          maxRows={3}
        />
        {allowEmojis && (
          <div className="add-emoji-div">
            <AddEmojiButton
              onClickEmoji={(emoji) => setValue((prev) => prev + emoji)}
            />
          </div>
        )}
      </FormControl>
    </div>
  )
}
