import { Suspense, useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import { isBrowser } from 'react-device-detect'
import { ToastContainer } from 'react-toastify'

import { HomeScreen } from './Pages/HomeScreen/HomeScreen'
import { CreateRoom } from './Pages/CreateRoom/CreateRoom'
import { JoinRoom } from './Pages/JoinRoom/JoinRoom'
import { Room } from './Pages/Room/Room'

import {
  RoomDetailsProvider,
  TimerProvider,
  UserProfileProvider,
  ChecklistProvider,
  MenuTabProvider
} from './hooks/context'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'

function App() {
  const [isLoading, setIsLoading] = useState(true)

  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  window.addEventListener('resize', () => {
    if (isBrowser) {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
  })

  useEffect(() => {
    const roomToken = localStorage.getItem('roomToken')
    axios.defaults.headers.common['roomToken'] = roomToken
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return <div></div>
  }

  return (
    <main className="main-div">
      <Suspense fallback={<CircularProgress />}>
        <RoomDetailsProvider>
          <TimerProvider>
            <UserProfileProvider>
              <ChecklistProvider>
                <Routes>
                  <Route path="/" element={<HomeScreen />} />
                  <Route path="/create" element={<CreateRoom />} />
                  <Route
                    path="/:roomName"
                    element={
                      <MenuTabProvider>
                        <Room />
                      </MenuTabProvider>
                    }
                  />
                  <Route path="/:roomName/join" element={<JoinRoom />} />
                  <Route
                    path="/:roomName/join/no-password"
                    element={<JoinRoom hidePassword />}
                  />
                </Routes>
              </ChecklistProvider>
            </UserProfileProvider>
          </TimerProvider>
        </RoomDetailsProvider>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </main>
  )
}

export default App
