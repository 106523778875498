import { createServer, Response } from 'miragejs'

import { CreateRoomResponse } from './data/CreateRoom'
import { JoinRoomResponse } from './data/JoinRoom'
import { CreateTimerResponse } from './data/CreateTimer'
import { FetchTimerResponse } from './data/FetchTimer'

export function makeServer() {
  let server = createServer({
    routes() {
      this.urlPrefix = 'http://mock.com'
      //CREATE A ROOM
      this.post('/api/room', () => {
        return CreateRoomResponse
      })

      this.post('/api/room/:id/join', () => {
        return JoinRoomResponse
      })

      this.get('/api/room/:id/timer', () => {
        return new Response(200, {}, FetchTimerResponse)
      })

      this.post('/api/room/:id/timer', () => {
        return CreateTimerResponse
      })

      this.post('/api/room/:id/checklist', () => {
        return {}
      })
    }
  })
  return server
}
