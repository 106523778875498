import { Typography } from '@mui/material'

import './AboutTab.scss'

export const AboutTab = () => {
  // Update Theme-Color
  document
    .querySelector("meta[name='theme-color']")
    .setAttribute('content', '#fdeecb')

  return (
    <div className="about-tab">
      <Typography className="title" variant="h5">
        What is Muffin?
      </Typography>
      <div className="content">
        <Typography variant="body1">
          Muffin is a social timer app that allows you to work together with
          your friends or team members. <br />
          <br />
          It uses a flexible Pomodoro timer, allowing you to work and break in
          chunks. This method is psychologically proven to increase
          productivity.
          <br />
          <br /> While working, you can use the Chat tab to talk to other people
          in the room, and the Checklist tab to organize your tasks, and share
          them with others for teamwork or accountability. <br />
          <br />
          During your break, you can play high score games with your friends.
          Enjoy working!
        </Typography>
      </div>
    </div>
  )
}
