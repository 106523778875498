import { createContext, useContext, useReducer } from 'react'

type TAction = {
  roomName?: string
  users?: any[]
  needsUpdate?: boolean
}
type RoomDetailsProviderProps = { children: React.ReactNode; options?: any }
interface IState {
  roomName: string
  users: any[]
  needsUpdate: boolean
}
interface IContext extends IState {
  dispatchRoomDetails: any
}

const RoomDetailsContext = createContext<IContext | undefined>(undefined)

const initialStateRoomDetails = {
  ID: '',
  roomName: '',
  users: [],
  needsUpdate: false
}
function RoomDetailsReducer(state = initialStateRoomDetails, action: TAction) {
  return { ...state, ...action }
}

function RoomDetailsProvider({
  children,
  options = {}
}: RoomDetailsProviderProps) {
  const [stateRoomDetails, dispatchRoomDetails] = useReducer(
    RoomDetailsReducer,
    {
      ...initialStateRoomDetails,
      ...options
    }
  )
  const value = { ...stateRoomDetails, dispatchRoomDetails }
  return (
    <RoomDetailsContext.Provider value={value}>
      {children}
    </RoomDetailsContext.Provider>
  )
}

function useRoomDetails() {
  const context = useContext(RoomDetailsContext)
  if (context === undefined) {
    throw new Error(`useRoomDetails must be used within a IDProvider`)
  }
  return context
}
export { initialStateRoomDetails, RoomDetailsProvider, useRoomDetails }
