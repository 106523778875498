import { toast } from 'react-toastify'

export const RECONNECTING_TOAST_ID = 'RECONNECTING_TOAST'

export const raiseConnectionLostToast = () => {
  toast.loading('Reconnecting...', {
    toastId: RECONNECTING_TOAST_ID,
    position: 'top-right',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  })
}
