import { useState, useEffect } from 'react'
import { Typography } from '@mui/material'

import { Button } from '../../../components/Button/Button'
import { AddAnotherSessionModal } from '../../../components/AddAnotherSessionModal/AddAnotherSessionModal'

import { useTimerContext } from '../../../hooks/context/TimerContext'

import './TimerTab.scss'

const workSessions = [5, 10, 15, 25, 55]
const breakSessions = [5, 10, 15, 25, 30]

export const TimerTab = ({
  handleUpdateTimer
}: {
  handleUpdateTimer: Function
}) => {
  const { workTime, breakTime } = useTimerContext()
  const [selectedWorkTime, setSelectedWorkTime] = useState<number>(workTime)
  const [selectedBreakTime, setSelectedBreakTime] = useState<number>(breakTime)
  const [workAnotherSessionOpen, setWorkAnotherSessionOpen] = useState(false)
  const [breakAnotherSessionOpen, setBreakAnotherSessionOpen] = useState(false)

  // Update Theme-Color
  document
    .querySelector("meta[name='theme-color']")
    .setAttribute('content', '#fdeecb')

  const handleSetTimer = () => {
    handleUpdateTimer(selectedWorkTime, selectedBreakTime)
  }

  useEffect(() => {
    setSelectedWorkTime(workTime)
    setSelectedBreakTime(breakTime)
  }, [workTime, breakTime])

  return (
    <section className="timer-main-section">
      <Typography className="title" variant="h6">
        Choose Your Schedule
      </Typography>
      <div className="form-div">
        <div className="session-div">
          <Typography variant="subtitle1">Work Sessions</Typography>
          {[
            ...workSessions,
            !workSessions.includes(selectedWorkTime) ? selectedWorkTime : null
          ]
            .filter((a) => a !== null)
            .sort((a, b) => a - b)
            .map((elem) => (
              <Button
                key={`work-session-${elem}`}
                label={String(elem)}
                onClick={() => setSelectedWorkTime(elem)}
                fullWidth
                isNotActive={selectedWorkTime !== elem}
                mediumFont
              />
            ))}
          <Button
            label={
              <>
                +<span className="another-session-txt">Other Session</span>
              </>
            }
            onClick={() => setWorkAnotherSessionOpen(true)}
            fullWidth
            mediumFont
            isNotActive
          />
        </div>
        <div className="session-div">
          <Typography variant="subtitle1">Break Sessions</Typography>
          {[
            ...breakSessions,
            !breakSessions.includes(selectedBreakTime)
              ? selectedBreakTime
              : null
          ]
            .filter((a) => a !== null)
            .sort((a, b) => a - b)
            .map((elem) => (
              <Button
                key={`break-session-${elem}`}
                label={String(elem)}
                onClick={() => setSelectedBreakTime(elem)}
                fullWidth
                isNotActive={selectedBreakTime !== elem}
                mediumFont
              />
            ))}
          <Button
            label={
              <>
                +<span className="another-session-txt">Other Session</span>
              </>
            }
            onClick={() => setBreakAnotherSessionOpen(true)}
            fullWidth
            mediumFont
            isNotActive
          />
        </div>
      </div>
      <div className="submit-button">
        <Button
          disabled={
            selectedWorkTime === workTime && selectedBreakTime === breakTime
          }
          label="New Timer"
          onClick={handleSetTimer}
        />
      </div>
      <AddAnotherSessionModal
        open={workAnotherSessionOpen}
        setOpen={setWorkAnotherSessionOpen}
        onClick={(value: number) => setSelectedWorkTime(value)}
      />
      <AddAnotherSessionModal
        open={breakAnotherSessionOpen}
        setOpen={setBreakAnotherSessionOpen}
        onClick={(value: number) => setSelectedBreakTime(value)}
      />
    </section>
  )
}
