import { Navigate } from 'react-router-dom'

export const HomeScreen = () => {
  const roomName = localStorage.getItem('roomName')
  const roomToken = localStorage.getItem('roomToken')

  if (!roomName?.length || !roomToken?.length) {
    localStorage.removeItem('roomToken')
    localStorage.removeItem('roomName')
    return <Navigate replace to="/create" />
  }

  return <Navigate replace to={`/${roomName}`} />
}
