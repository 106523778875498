import { CircularProgress } from '@mui/material'

import './LoadingTab.scss'

export const LoadingTab = () => {
  return (
    <div className="loading-tab-main-div">
      <CircularProgress size={64} />
    </div>
  )
}
