import { useState } from 'react'
import { Typography } from '@mui/material'

import { Avatar } from '../Avatar/Avatar'

import { useRoomDetails } from '../../hooks/context/RoomDetailsContext'

import { AddUserPopover } from '../../Pages/Room/ChecklistTab/AddUserPopover/AddUserPopover'

import AddButtonIcon from '../../assets/checklist-icons/add-bttn.svg'

import './AddUsersButton.scss'
export const AddUsersButton = ({
  userIds,
  setUserIds,
  withoutText
}: {
  userIds: string[]
  setUserIds: Function
  withoutText?: boolean
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { users } = useRoomDetails()

  const handleOpenAddUsers = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseAddUsers = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div className="add-users-main-div">
        {!!userIds.length && (
          <div className="avatars-container">
            {userIds.map((uId) => {
              const { picURL, name } =
                users.find((elem) => elem.id === uId) ?? {}
              return (
                <Avatar key={`avatar-${uId}`} picURL={picURL} name={name} />
              )
            })}
          </div>
        )}
        <button onClick={handleOpenAddUsers} className="add-member-bttn">
          <img alt="Add User" src={AddButtonIcon} />
          {!withoutText && (
            <Typography variant="subtitle1">Add Member</Typography>
          )}
        </button>
      </div>
      <AddUserPopover
        anchorEl={anchorEl}
        handleClose={handleCloseAddUsers}
        checked={userIds}
        setChecked={setUserIds}
      />
    </>
  )
}
