import { RoomDetails } from '../RoomDetails/RoomDetails'
import { ChooseTimer } from './ChooseTimer/ChooseTimer'
import { Timer } from './Timer/Timer'

import { useTimerContext } from '../../../hooks/context/TimerContext'

import './Home.scss'

export const Home = ({
  isSelected,
  handleLogout,
  handleUpdateTimerState,
  handleUpdateTimer
}: {
  isSelected: boolean
  handleLogout: any
  handleUpdateTimerState: Function
  handleUpdateTimer: Function
}) => {
  const { state: timerState } = useTimerContext()

  // Update Theme-Color
  if (isSelected && !timerState.includes('pause')) {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute('content', 'white')
  } else if (isSelected && timerState.includes('pause')) {
    document
      .querySelector("meta[name='theme-color']")
      .setAttribute('content', '#ff7b7b')
  }

  return (
    <div
      className={
        (timerState.includes('pause')
          ? 'home-main-div pause'
          : 'home-main-div') + (!isSelected ? ' mobile-hide' : '')
      }
    >
      {timerState === 'none' && (
        <ChooseTimer handleUpdateTimer={handleUpdateTimer} />
      )}
      {timerState !== 'none' && (
        <Timer handleUpdateTimerState={handleUpdateTimerState} />
      )}
      <RoomDetails handleLogout={handleLogout} />
    </div>
  )
}
