import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { LoadingTab } from './LoadingTab/LoadingTab'

import { Menu } from './Menu'
import { MenuMobile } from './MenuMobile'
import { NavbarMobile } from './NavbarMobile'
import { Home } from './Home/Home'

//TABS
import { TimerTab } from './TimerTab/TimerTab'
import { ProfileTab } from './ProfileTab/ProfileTab'
import { ChatTab } from './ChatTab/ChatTab'
import { ChecklistTab } from './ChecklistTab/ChecklistTab'
import { AboutTab } from './AboutTab/AboutTab'

import {
  useRoomDetails,
  useTimerContext,
  useUserProfileContext,
  useChecklist,
  useMenuTab
} from '../../hooks/context'

import { createWebsocket } from '../../hooks/queries/helper'
import {
  RECONNECTING_TOAST_ID,
  raiseConnectionLostToast
} from '../../utils/raise-connection-lost-toast'

import './Room.scss'

export const Room = () => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false)
  const { roomName } = useParams()
  const { dispatchRoomDetails } = useRoomDetails()
  const {
    id: userId,
    name: userName,
    email: userEmail,
    about: userAbout,
    picURL: userPicURL,
    needsUpdate: needsUpdateProfile,
    dispatchUserProfile
  } = useUserProfileContext()
  const { dispatchTimer } = useTimerContext()
  const { tab: tabSelected } = useMenuTab()
  const { dispatchChecklist } = useChecklist()
  const navigate = useNavigate()

  const [ws, setWs] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [chatMessages, setChatMessages] = useState<any[]>([])
  const [messagesToRead, setMessagesToRead] = useState<number>(0)

  const handleLogout = () => {
    ws?.close()
    localStorage.removeItem('roomToken')

    navigate('/create')
  }

  // Function used to update the timer state
  const handleUpdateTimerState = (newState: string) => {
    if (ws?.readyState && ws?.readyState !== ws.OPEN) {
      raiseConnectionLostToast()
      subscribeRoom()
    }
    ws.send(
      JSON.stringify({
        newTimerState: newState
      })
    )
  }

  // Function used to update the timer
  const handleUpdateTimer = (workTime: number, breakTime: number) => {
    if (ws?.readyState && ws?.readyState !== ws.OPEN) {
      raiseConnectionLostToast()
      subscribeRoom()
    }
    ws.send(
      JSON.stringify({
        timer: {
          workTime,
          breakTime
        }
      })
    )
  }

  const subscribeRoom = () => {
    const websocket = createWebsocket(roomName)
    setWs(websocket)
    websocket.onopen = () => {
      console.log('Joined room socket.')
      // First message is the room token
      const roomToken = localStorage.getItem('roomToken')
      websocket.send(JSON.stringify({ token: roomToken }))
    }

    websocket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      // If there's error redirect to join page
      if (data.error) {
        if (data.error === 'Please sign up to join!') {
          navigate(`/${roomName}/join/no-password`)
          toast.success(data.error)
          return
        }
        if (data.error === 'Please login to join!') {
          navigate(`/${roomName}/join`)
          toast.success(data.error)
          return
        }
        toast.error(data.error)
        return
      }

      // Update Timer
      if (data.timer) {
        const { state, timeLeft, workTime, breakTime, timestamp } = data.timer
        dispatchTimer({ state, timeLeft, workTime, breakTime, timestamp })
      }

      // Update Users
      if (data.users) {
        dispatchRoomDetails({
          users: data.users,
          needsUpdate: false
        })

        // Update current User Profile
        if (userId?.length) {
          const foundUser = data.users.find((u) => u.id === userId)
          if (foundUser) {
            dispatchUserProfile({
              name: foundUser.name,
              email: foundUser.email,
              about: foundUser.about,
              picURL: foundUser.picURL,
              needsUpdate: false
            })
          }
        }
      }

      // Update Messages
      if (data.messages) {
        setChatMessages(data.messages)
      }

      if (data.newMessage) {
        setChatMessages((prev) => [...prev, data.newMessage])
        setMessagesToRead((prev) => prev + 1)
      }

      if (data.checklist) {
        const { checklist } = data
        dispatchChecklist({
          tasks: checklist.filter((c) => c.type === 'tasks'),
          inProgress: checklist.filter((c) => c.type === 'inProgress'),
          done: checklist.filter((c) => c.type === 'done')
        })
      }

      if (data.cardLabels) {
        dispatchChecklist({
          labels: data.cardLabels
        })
      }

      // If loggedIn -> stop loading
      // loggedIn response has the user logged in data
      if (data.loggedIn) {
        dispatchUserProfile({
          id: data.loggedIn.id,
          name: data.loggedIn.name,
          email: data.loggedIn.email,
          picURL: data.loggedIn.picURL,
          about: data.loggedIn.about
        })
        setIsLoading(false)
        toast.update(RECONNECTING_TOAST_ID, {
          render: 'Reconnected!',
          type: 'success',
          isLoading: false
        })
      }
    }

    websocket.onerror = () => {
      toast.dismiss()
      console.warn('Could not join this room.')
      navigate(`/create`)
    }

    return () => {
      websocket.close()
    }
  }

  useEffect(() => {
    subscribeRoom()
  }, [])

  useEffect(() => {
    if (isOpenMobileMenu) {
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute('content', '#fdeecb')
    }
  }, [isOpenMobileMenu])

  // Send the new UserProfile via websocket
  useEffect(() => {
    if (needsUpdateProfile) {
      if (ws?.readyState && ws?.readyState !== ws.OPEN) {
        raiseConnectionLostToast()
        subscribeRoom()
      }
      ws.send(
        JSON.stringify({
          newProfile: {
            name: userName,
            email: userEmail,
            about: userAbout,
            picURL: userPicURL
          }
        })
      )
    }
  }, [userName, userEmail, userAbout, userPicURL])

  useEffect(() => {
    if (tabSelected === 'Chat') {
      // Reset notification counter
      setMessagesToRead(0)
    }
  }, [tabSelected, chatMessages])

  if (isLoading) {
    return (
      <section className="room-main-section">
        <Menu handleLogout={handleLogout} messagesToRead={messagesToRead} />
        <div className="room-tab-main-container">
          <LoadingTab />
        </div>
      </section>
    )
  }

  return (
    <section className="room-main-section">
      <NavbarMobile
        isOpen={isOpenMobileMenu}
        setIsOpen={setIsOpenMobileMenu}
        hasNotifications={!!messagesToRead}
      />
      <Menu handleLogout={handleLogout} messagesToRead={messagesToRead} />
      <div className="tab-wrapper">
        {tabSelected === 'Timer' && (
          <TimerTab handleUpdateTimer={handleUpdateTimer} />
        )}
        {tabSelected === 'Profile' && <ProfileTab />}
        {tabSelected === 'Chat' && <ChatTab messages={chatMessages} ws={ws} />}
        {tabSelected === 'About' && <AboutTab />}
        {tabSelected === 'Checklist' && <ChecklistTab />}
        <Home
          isSelected={tabSelected === 'Home'}
          handleLogout={handleLogout}
          handleUpdateTimerState={handleUpdateTimerState}
          handleUpdateTimer={handleUpdateTimer}
        />
      </div>
      {isOpenMobileMenu && (
        <MenuMobile
          setIsOpenMobileMenu={setIsOpenMobileMenu}
          handleLogout={handleLogout}
          messagesToRead={messagesToRead}
        />
      )}
    </section>
  )
}
