import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Typography, Modal } from '@mui/material'
import { toast } from 'react-toastify'

import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { CardLabel } from '../../../../components/CardLabel/CardLabel'
import { AddUsersButton } from '../../../../components/AddUsersButton/AddUsersButton'
import { ChangeCardTypePopover } from '../ChangeCardTypePopover/ChangeCardTypePopover'
import { AddLabelPopover } from '../AddLabelPopover/AddLabelPopover'

import { useUpdateChecklistCard } from '../../../../hooks/queries/checklist'

import { Validators } from '../../../../utils/validators'
import {
  ChecklistCardType,
  CardTypeToLabel,
  ChecklistCardLabelType
} from '../../../../types/ChecklistCard'

import './EditCard.scss'

type EditCardProps = {
  open: boolean
  handleClose: any
  card: ChecklistCardType
}

export const EditCard = ({ open, handleClose, card }: EditCardProps) => {
  const updateChecklistCard = useUpdateChecklistCard()
  const { roomName } = useParams()

  const { label, type, title, description, users } = card
  const [newLabel, setNewLabel] = useState<ChecklistCardLabelType | null>(label)
  const [newType, setNewType] = useState<'tasks' | 'inProgress' | 'done'>(type)
  const [newTitle, setNewTitle] = useState<string>(title)
  const [newDescription, setNewDescription] = useState<string>(description)
  const [newUsers, setNewUsers] = useState<string[]>([])

  const [addLabelAnchorEl, setAddLabelAnchorEl] =
    useState<HTMLButtonElement | null>(null)
  const [changeCardTypeAnchorEl, setChangeCardTypeAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const buttonDisabled = !Validators.cardTitle.test(title)

  const onClickSave = () => {
    updateChecklistCard.mutate(
      {
        roomName,
        cardID: card.id,
        type: newType,
        label: newLabel,
        title: newTitle,
        description: newDescription,
        users: newUsers
      },
      {
        onError: (error: any) => {
          toast.error(error?.data?.error?.message ?? error.message)
        },
        onSuccess: () => {
          handleClose()
        }
      }
    )
  }

  useEffect(() => {
    setNewLabel(label)
    setNewType(type)
    setNewTitle(title)
    setNewDescription(description)
    setNewUsers(users)
  }, [open])

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className="edit-card-main-div">
          <Typography className="header" variant="h6">
            In list
            <button
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setChangeCardTypeAnchorEl(event.currentTarget)
              }
            >
              <Typography variant="h6">{CardTypeToLabel[newType]}</Typography>
            </button>
          </Typography>
          <CardLabel
            label={newLabel?.name}
            color={newLabel?.color}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
              setAddLabelAnchorEl(event.currentTarget)
            }
          />
          <Input
            label="Title"
            placeholder="Enter New Title"
            value={newTitle}
            setValue={setNewTitle}
            error={!Validators.cardTitle.test(newTitle)}
            darker
            allowEmojis
          />
          <Input
            label="Description"
            placeholder="Add Text"
            value={newDescription}
            setValue={setNewDescription}
            error={!Validators.cardDescription.test(newDescription)}
            darker
            multiline
            allowEmojis
          />
          <AddUsersButton userIds={newUsers} setUserIds={setNewUsers} />
          <div className="bttn-container">
            <Button
              disabled={buttonDisabled}
              label="Save"
              onClick={onClickSave}
            />
          </div>
        </div>
      </Modal>
      <AddLabelPopover
        anchorEl={addLabelAnchorEl}
        handleClose={() => setAddLabelAnchorEl(null)}
        checked={newLabel}
        setChecked={setNewLabel}
        cardID={card.id}
      />
      <ChangeCardTypePopover
        anchorEl={changeCardTypeAnchorEl}
        handleClose={() => setChangeCardTypeAnchorEl(null)}
        type={newType}
        setType={setNewType}
      />
    </>
  )
}
