import { Typography, Popover } from '@mui/material'

import CheckIcon from '../../../../assets/checklist-icons/check-orange.svg'

import { CardTypeToLabel } from '../../../../types/ChecklistCard'

import './ChangeCardTypePopover.scss'

export const ChangeCardTypePopover = ({
  anchorEl,
  handleClose,
  type,
  setType
}: {
  anchorEl: HTMLButtonElement | null
  handleClose: any
  type: string
  setType: Function
}) => {
  return (
    <Popover
      classes={{ paper: 'type-popover-main-div' }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {['tasks', 'inProgress', 'done'].map((t, index) => (
        <div key={`popover-type-${index}`} className="type-div">
          <div className="check-wrapper">
            {type === t && <img className="" alt="Check" src={CheckIcon} />}
          </div>
          <button onClick={() => setType(t)}>
            <Typography variant="body1">{CardTypeToLabel[t]}</Typography>
          </button>
        </div>
      ))}
    </Popover>
  )
}
