import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Popover, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import { CardLabel } from '../../../../components/CardLabel/CardLabel'
import { ChecklistCardDetails } from '../ChecklistCardDetails/ChecklistCardDetails'
import { EditCard } from '../EditCard/EditCard'
import { AddUsersButton } from '../../../../components/AddUsersButton/AddUsersButton'

import { useRoomDetails } from '../../../../hooks/context/RoomDetailsContext'
import {
  useUpdateChecklistCard,
  useDeleteChecklistCard
} from '../../../../hooks/queries/checklist'

import DotsIcon from '../../../../assets/checklist-icons/dots.svg'
import DotsHoverIcon from '../../../../assets/checklist-icons/dots-hover.svg'
import CommentsIcon from '../../../../assets/checklist-icons/comment.svg'

//Types
import { ChecklistCardType } from '../../../../types/ChecklistCard'

import './ChecklistCard.scss'

export const ChecklistCard = ({ card }: { card: ChecklistCardType }) => {
  const deleteChecklistCard = useDeleteChecklistCard()
  const updateChecklistCard = useUpdateChecklistCard()

  const [detailsOpen, setDetailsOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const { roomName } = useParams()
  const { users } = useRoomDetails()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [newUsers, setNewUsers] = useState<string[]>(card.users)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    deleteChecklistCard.mutate({ roomName, cardID: card.id })
  }

  const updateChecklistCardUsers = (newValue: string[]) => {
    setNewUsers(newValue)
    updateChecklistCard.mutate(
      {
        roomName,
        users: newValue,
        cardID: card.id
      },
      {
        onError: (error: any) => {
          // Go back to previous state
          toast.error(error?.data?.error?.message ?? error.message)
          setNewUsers(users)
        }
      }
    )
  }

  return (
    <>
      <div className="card-main-div">
        <div className="card-header">
          <CardLabel label={card.label?.name} color={card.label?.color} />
          <button className="more-options-bttn" onClick={handleOpenMenu}>
            <img className="hover-icon" alt="hover-dots" src={DotsHoverIcon} />
            <img className="icon" alt="dots" src={DotsIcon} />
          </button>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <button onClick={() => setEditOpen(true)} className="edit">
              Edit
            </button>
            <button onClick={handleDelete} className="delete">
              Delete
            </button>
          </Popover>
        </div>
        <div>
          <Typography className="card-title">{card.title}</Typography>
          <Typography className="card-description" variant="subtitle2">
            {card.description}
          </Typography>
        </div>
        <div className="card-footer">
          <AddUsersButton
            userIds={newUsers}
            setUserIds={updateChecklistCardUsers}
            withoutText
          />
          <button className="comments-div" onClick={() => setDetailsOpen(true)}>
            <Typography variant="subtitle2">{`${card.comments.length} comments`}</Typography>
            <img alt="comments" src={CommentsIcon} />
          </button>
        </div>
      </div>
      <ChecklistCardDetails
        open={detailsOpen}
        setOpen={setDetailsOpen}
        card={card}
      />
      <EditCard
        open={editOpen}
        handleClose={() => {
          setEditOpen(false)
          setAnchorEl(null)
        }}
        card={card}
      />
    </>
  )
}
