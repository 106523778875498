import { createContext, useContext, useReducer } from 'react'

import {
  ChecklistCardType,
  ChecklistCardLabelType
} from '../../types/ChecklistCard'

type TAction = {
  tasks?: ChecklistCardType[]
  inProgress?: ChecklistCardType[]
  done?: ChecklistCardType[]
  labels?: ChecklistCardLabelType[]
}
type ChecklistProviderProps = { children: React.ReactNode; options?: any }
interface IState {
  tasks: ChecklistCardType[]
  inProgress: ChecklistCardType[]
  done: ChecklistCardType[]
  labels: ChecklistCardLabelType[]
}
interface IContext extends IState {
  dispatchChecklist: any
}

const ChecklistContext = createContext<IContext | undefined>(undefined)

const initialStateChecklist = {
  tasks: [],
  inProgress: [],
  done: [],
  labels: []
}
function ChecklistReducer(state = initialStateChecklist, action: TAction) {
  return { ...state, ...action }
}

function ChecklistProvider({ children, options = {} }: ChecklistProviderProps) {
  const [stateChecklist, dispatchChecklist] = useReducer(ChecklistReducer, {
    ...initialStateChecklist,
    ...options
  })
  const value = { ...stateChecklist, dispatchChecklist }
  return (
    <ChecklistContext.Provider value={value}>
      {children}
    </ChecklistContext.Provider>
  )
}

function useChecklist() {
  const context = useContext(ChecklistContext)
  if (context === undefined) {
    throw new Error(`useChecklist must be used within a IDProvider`)
  }
  return context
}
export { initialStateChecklist, ChecklistProvider, useChecklist }
