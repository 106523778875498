import { Typography, Badge } from '@mui/material'
import { Divide as Hamburger } from 'hamburger-react'

import Logo from '../../assets/logo.svg'
import BackIcon from '../../assets/menu-icons/back.svg'

import { useMenuTab } from '../../hooks/context'

import './NavbarMobile.scss'

export const NavbarMobile = ({
  isOpen,
  setIsOpen,
  hasNotifications
}: {
  isOpen: boolean
  setIsOpen: any
  hasNotifications: boolean
}) => {
  const { tab, dispatchMenuTab } = useMenuTab()

  return (
    <nav className="navbar-main-container">
      <div
        className="top-section"
        style={{
          backgroundColor: ['About', 'Create Card'].includes(tab)
            ? 'var(--main-bg-color)'
            : 'transparent'
        }}
      >
        <img alt="Muffin" src={Logo} height={32} width={32} />
        {hasNotifications && (
          <Badge
            className="menu-badge"
            variant="dot"
            color="primary"
            overlap="circular"
          >
            <Hamburger toggled={isOpen} toggle={setIsOpen} size={24} />
          </Badge>
        )}
        {!hasNotifications && (
          <Hamburger toggled={isOpen} toggle={setIsOpen} size={24} />
        )}
      </div>
      {tab !== 'Home' && !isOpen && (
        <div className="bottom-section mobile-show">
          <button
            onClick={() =>
              dispatchMenuTab({
                tab: tab === 'Create Card' ? 'Checklist' : 'Home'
              })
            }
          >
            <img alt="Back" src={BackIcon} height={24} width={24} />
          </button>
          <Typography variant="body1">{tab}</Typography>
          <div></div>
        </div>
      )}
    </nav>
  )
}
