import { useState } from 'react'
import { Popover } from '@mui/material'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { CardLabel } from '../../../../components/CardLabel/CardLabel'
import { AddLabelModal } from '../AddLabelModal/AddLabelModal'

import CheckIcon from '../../../../assets/checklist-icons/check-orange.svg'
import AddButtonIcon from '../../../../assets/checklist-icons/add-bttn.svg'

import { useChecklist } from '../../../../hooks/context/ChecklistContext'
import { useUpdateChecklistCard } from '../../../../hooks/queries/checklist'

import { ChecklistCardLabelType } from '../../../../types/ChecklistCard'

import './AddLabelPopover.scss'

export const AddLabelPopover = ({
  cardID,
  anchorEl,
  handleClose,
  checked,
  setChecked
}: {
  cardID: string
  anchorEl: HTMLButtonElement | null
  handleClose: any
  checked: ChecklistCardLabelType | null
  setChecked: Function
}) => {
  const updateChecklistCard = useUpdateChecklistCard()
  const { roomName } = useParams()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { labels } = useChecklist()

  const updateChecklistCardLabel = (newValue: ChecklistCardLabelType) => {
    updateChecklistCard.mutate(
      {
        roomName,
        label: newValue,
        cardID
      },
      {
        onError: (error: any) => {
          // Go back to previous state
          toast.error(error?.data?.error?.message ?? error.message)
        }
      }
    )
  }

  const handleOnClick = (label: ChecklistCardLabelType) => {
    setChecked(label)
  }

  return (
    <Popover
      classes={{ paper: 'label-popover-main-div' }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      {labels.map((l, index) => (
        <div key={`popover-label-${index}`} className="label-row">
          <div className="check-wrapper">
            {checked?.name === l.name && (
              <img className="" alt="Check" src={CheckIcon} />
            )}
          </div>
          <CardLabel
            label={l.name}
            color={l.color}
            onClick={() => handleOnClick(l)}
          />
        </div>
      ))}
      <button onClick={() => setOpenModal(true)} className="add-label-bttn">
        <div className="check-wrapper"></div>
        <img width={28} height={28} alt="Add" src={AddButtonIcon} />
      </button>
      <AddLabelModal
        open={openModal}
        setOpen={setOpenModal}
        onClickAdd={updateChecklistCardLabel}
        createOnly
        isLoading={updateChecklistCard.isLoading}
      />
    </Popover>
  )
}
