import { StrictMode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import * as ReactDOM from 'react-dom'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes
} from '@mui/material/styles'
import { QueryClient, QueryCache, QueryClientProvider } from 'react-query'

import App from './App'

import { makeServer } from './mocks/server'

import './index.scss'

// Create a client
const queryClient = new QueryClient({
  queryCache: new QueryCache({}),
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: Infinity
    }
  }
})

//THEME MUI
let theme = createTheme({
  palette: {
    primary: {
      main: '#FCAA55'
    },
    secondary: {
      main: '#FEB65E'
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif'
    ].join(','),
    subtitle1: {
      color: 'var(--grey-30)'
    }
  }
})
theme = responsiveFontSizes(theme)

// Mock Server Init
if (['test'].includes(process.env.NODE_ENV)) {
  makeServer()
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
)
