import Muffin1 from './1.1.svg'
import Muffin2 from './1.2.svg'
import Muffin3 from './1.3.svg'
import Muffin4 from './1.4.svg'
import Muffin5 from './1.5.svg'
import Muffin6 from './1.6.svg'
import Muffin7 from './1.7.svg'
import Muffin8 from './1.8.svg'

export const MuffinAnimation = [
  Muffin1,
  Muffin2,
  Muffin3,
  Muffin4,
  Muffin5,
  Muffin6,
  Muffin7,
  Muffin8
]
