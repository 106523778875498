import axios from 'axios'

import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useTimerContext } from '../context/TimerContext'

import { postResolve, fetchResolve } from './helper'

//TYPES
import { CreateRoomResponseType } from '../../types/CreateRoomReponse'
import { FetchTimerResponseType } from '../../types/FetchTimerReponse'

export function createRoomMutation() {
  const navigate = useNavigate()
  return useMutation(
    async ({
      name,
      email,
      roomName,
      roomPassword,
      allowNewsletter
    }: {
      name: string
      email: string
      roomName: string
      roomPassword?: string
      allowNewsletter: boolean
    }) =>
      postResolve('/room', {
        name,
        email,
        roomName,
        roomPassword,
        allowNewsletter
      }),
    {
      onSuccess: (
        { roomName, roomToken }: CreateRoomResponseType,
        { name, email }
      ) => {
        localStorage.setItem('name', name)
        localStorage.setItem('email', email)
        localStorage.setItem('roomToken', roomToken)
        localStorage.setItem('roomName', roomName)
        axios.defaults.headers.common['roomToken'] = roomToken
        toast.dismiss()
        navigate(`/${roomName}`)
      },
      onError: (error: any) => {
        toast.error(error?.data?.error?.message ?? error.message)
      }
    }
  )
}

export function joinRoomMutation() {
  const navigate = useNavigate()
  return useMutation(
    async ({
      name,
      email,
      roomPassword,
      roomName,
      allowNewsletter
    }: {
      name: string
      email: string
      roomPassword?: string
      roomName: string
      allowNewsletter: boolean
    }) =>
      await postResolve(`/room/${roomName}/join`, {
        name,
        email,
        roomPassword,
        allowNewsletter
      }),
    {
      onSuccess: ({ roomName, roomToken }: CreateRoomResponseType) => {
        localStorage.setItem('roomToken', roomToken)
        localStorage.setItem('roomName', roomName)
        axios.defaults.headers.common['roomToken'] = roomToken
        toast.dismiss()
        navigate(`/${roomName}`)
      },
      onError: (error: any) => {
        toast.error(error?.data?.error?.message ?? error.message)
      }
    }
  )
}

export function useFetchTimer(roomName: string) {
  return useQuery<FetchTimerResponseType>([`useFetchTimer`], async () =>
    fetchResolve(`/room/${roomName}/timer`)
  )
}

export function createRoomTimerMutation() {
  const { dispatchTimer } = useTimerContext()
  return useMutation(
    async ({
      breakTime,
      workTime,
      roomName
    }: {
      breakTime: number
      workTime: number
      roomName: string
    }) =>
      await postResolve(`/room/${roomName}/timer`, {
        breakTime,
        workTime
      }),
    {
      onSuccess: ({
        timerState,
        endTimestamp,
        stopTime,
        workTime,
        breakTime
      }: any) => {
        dispatchTimer({
          timerState,
          endTimestamp,
          stopTime,
          workTime,
          breakTime
        })
      }
    }
  )
}
