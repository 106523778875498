import { useState, useRef, useEffect } from 'react'
import * as moment from 'moment'
import { TextField, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { Avatar } from '../../../components/Avatar/Avatar'
import { Button } from '../../../components/Button/Button'
import { AddEmojiButton } from '../../../components/AddEmojiButton/AddEmojiButton'

import { useRoomDetails } from '../../../hooks/context/RoomDetailsContext'
import { useUserProfileContext } from '../../../hooks/context/UserProfileContext'

import AttachmentIcon from '../../../assets/chat-icons/attachment.svg'

import { raiseConnectionLostToast } from '../../../utils/raise-connection-lost-toast'

import { User } from '../../../types/User'
import { ChatMessageType } from '../../../types/ChatMessage'

import './ChatTab.scss'

const ChatMessageContainer = ({
  user,
  messages,
  fromCurrentUser,
  containerIndex
}: {
  user: User
  messages: ChatMessageType[]
  fromCurrentUser: boolean
  containerIndex: number
}) => {
  if (!user) {
    return <div></div>
  }

  if (fromCurrentUser) {
    return (
      <div className="chat-message-main-div my-message">
        <div className="messages-container">
          {messages.map((m, index) => (
            <div
              key={`message-container-${containerIndex}-message-${index}`}
              className="chat-message-content condensed"
            >
              <Typography variant="body1">{m.message}</Typography>
              <Typography variant="subtitle2">
                {moment(m.timestamp).format('h:mm A')}
              </Typography>
            </div>
          ))}
        </div>
        <div>
          <Avatar picURL={user.picURL} name={user.name} />
        </div>
      </div>
    )
  }

  return (
    <div className="chat-message-main-div">
      <div>
        <Avatar picURL={user.picURL} name={user.name} />
      </div>
      <div className="messages-container">
        {messages.map((m, index) => {
          if (index !== 0) {
            return (
              <div key="1" className="chat-message-content condensed">
                <Typography variant="body1">{m.message}</Typography>
                <Typography variant="subtitle2">
                  {moment(m.timestamp).format('h:mm A')}
                </Typography>
              </div>
            )
          }
          return (
            <div key="1" className="chat-message-content">
              <div className="chat-message-header">
                <Typography variant="subtitle1">{user.name}</Typography>
                <Typography variant="subtitle2">
                  {moment(m.timestamp).format('h:mm A')}
                </Typography>
              </div>
              <Typography variant="body1">{m.message}</Typography>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const ChatTab = ({
  ws,
  messages
}: {
  ws: WebSocket
  messages: ChatMessageType[]
}) => {
  const messagesEndRef = useRef<any>()

  const { roomName } = useParams()
  const [inputValue, setInputValue] = useState<string>('')
  const [parsedMessages, setParsedMessages] = useState<
    { userId: string; messages: ChatMessageType[] }[]
  >([])
  const { users } = useRoomDetails()
  const { id: userId } = useUserProfileContext()

  // Update Theme-Color
  document
    .querySelector("meta[name='theme-color']")
    .setAttribute('content', '#fdeecb')

  const onClickSend = () => {
    if (ws?.readyState && ws?.readyState !== ws?.OPEN) {
      raiseConnectionLostToast()
      return
    }
    ws.send(JSON.stringify({ newMessage: inputValue }))
    setInputValue('')
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClickSend()
    }
  }

  useEffect(() => {
    const newParsedMessages = []
    let lastUser = null
    messages.forEach((m) => {
      if (m.userId !== lastUser) {
        newParsedMessages.push({ userId: m.userId, messages: [] })
      }
      newParsedMessages[newParsedMessages.length - 1].messages.push(m)
      lastUser = m.userId
    })
    setParsedMessages(newParsedMessages)
    messagesEndRef?.current?.scrollIntoView({})
  }, [messages])

  return (
    <section className="chat-tab">
      <div className="chat-main-container">
        <div className="chat-header mobile-hide">
          <Typography
            variant="h6"
            className="chat-header-txt"
          >{`${roomName} Chat`}</Typography>
          <Typography variant="subtitle2">{`${users.length} ${
            users.length === 1 ? 'member' : 'members'
          }`}</Typography>
        </div>
        <div className="chat-messages-container">
          {parsedMessages.map((m, index) => {
            return (
              <ChatMessageContainer
                key={`chat-message-${index}`}
                messages={m.messages}
                user={users.find((u) => u.id === m.userId)}
                fromCurrentUser={m.userId === userId}
                containerIndex={index}
              />
            )
          })}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input-div">
          <button>
            <img alt="Attach" src={AttachmentIcon} />
          </button>
          <TextField
            placeholder="Write message..."
            variant="standard"
            size="small"
            className="chat-input-field"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            onKeyPress={handleKeyPress}
            fullWidth
          />
          <AddEmojiButton
            onClickEmoji={(emoji) => setInputValue((prev) => prev + emoji)}
          />
          <Button
            label="Send"
            small
            disabled={!inputValue.trim().length}
            onClick={onClickSend}
          />
        </div>
      </div>
    </section>
  )
}
