import { Typography, Tooltip } from '@mui/material'

import EditIcon from '../../assets/avatar-icons/edit.svg'

import './Avatar.scss'

export const Avatar = ({
  name = '',
  picURL = '',
  size,
  onEdit
}: {
  name: string
  picURL: string
  size?: number
  onEdit?: any
}) => {
  return (
    <Tooltip title={name} placement="top" arrow>
      <div
        className="avatar-div"
        style={{
          width: size ?? 32,
          height: size ?? 32,
          border: !onEdit
            ? '1px solid var(--grey-85)'
            : '2px solid var(--orange-66)'
        }}
      >
        {picURL.length !== 0 && (
          <img className="user-picture" alt={name} src={picURL} />
        )}
        {picURL.length === 0 && (
          <Typography variant={(size ?? 0) < 64 ? 'body1' : 'h5'}>
            {name?.[0]?.toUpperCase() ?? '?'}
          </Typography>
        )}
        {onEdit && (
          <>
            <label htmlFor="file-upload" className="edit-button">
              <img alt="Edit" src={EditIcon} />
            </label>
            <input
              id="file-upload"
              data-testid="file-upload"
              type="file"
              onChange={onEdit}
            />
          </>
        )}
      </div>
    </Tooltip>
  )
}
