import { createContext, useContext, useReducer } from 'react'

type TAction = {
  tab: string
}
type MenuTabProviderProps = { children: React.ReactNode; options?: any }
interface IState {
  tab: string
}
interface IContext extends IState {
  dispatchMenuTab: any
}

const MenuTabContext = createContext<IContext | undefined>(undefined)

const initialStateMenuTab = {
  tab: 'Home'
}
function MenuTabReducer(state = initialStateMenuTab, action: TAction) {
  return { ...state, ...action }
}

function MenuTabProvider({ children, options = {} }: MenuTabProviderProps) {
  const [stateMenuTab, dispatchMenuTab] = useReducer(MenuTabReducer, {
    ...initialStateMenuTab,
    ...options
  })
  const value = { ...stateMenuTab, dispatchMenuTab }
  return (
    <MenuTabContext.Provider value={value}>{children}</MenuTabContext.Provider>
  )
}

function useMenuTab() {
  const context = useContext(MenuTabContext)
  if (context === undefined) {
    throw new Error(`useMenuTab must be used within a MenuTabProvider`)
  }
  return context
}
export { initialStateMenuTab, MenuTabProvider, useMenuTab }
