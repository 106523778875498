import axios from 'axios'

import { CustomError } from '../../types/CustomError'

let apiURL = 'https://muffin-workers.influencee.workers.dev/api'
if (['development', 'test'].includes(process.env.NODE_ENV)) {
  // apiURL = 'http://mock.com/api'
  apiURL = 'http://127.0.0.1:8787/api'
}

export async function fetchResolve(url) {
  try {
    const res = await axios.get(apiURL + url)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

export async function postResolve(url, body) {
  try {
    const res = await axios.post(apiURL + url, body)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

export async function patchResolve(url, body) {
  try {
    const res = await axios.patch(apiURL + url, body)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

export async function deleteResolve(url) {
  try {
    const res = await axios.delete(apiURL + url)
    return res.data
  } catch (e: any) {
    const { status, data } = e.response ?? {}
    const newError: CustomError = new Error(e.message) as any
    newError.status = status
    newError.data = data
    throw newError
  }
}

export function createWebsocket(roomName) {
  return new WebSocket(
    `${apiURL
      .replace('http://', 'ws://')
      .replace('https://', 'wss://')}/room/${roomName}/websocket`
  )
}
