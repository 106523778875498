import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Modal, Typography, Divider, TextField } from '@mui/material'
import * as moment from 'moment'
import { toast } from 'react-toastify'

import { CardLabel } from '../../../../components/CardLabel/CardLabel'
import { Avatar } from '../../../../components/Avatar/Avatar'
import { AddEmojiButton } from '../../../../components/AddEmojiButton/AddEmojiButton'
import { Button } from '../../../../components/Button/Button'
import { AddUsersButton } from '../../../../components/AddUsersButton/AddUsersButton'
import { AddLabelPopover } from '../AddLabelPopover/AddLabelPopover'
import { ChangeCardTypePopover } from '../ChangeCardTypePopover/ChangeCardTypePopover'

import { useRoomDetails } from '../../../../hooks/context/RoomDetailsContext'
import {
  useUpdateChecklistCard,
  useCreateChecklistComment
} from '../../../../hooks/queries/checklist'

//Types
import {
  ChecklistCardType,
  CardTypeToLabel,
  ChecklistCardCommentType,
  ChecklistCardLabelType
} from '../../../../types/ChecklistCard'

import './ChecklistCardDetails.scss'

const ChecklistCardComment = ({
  comment
}: {
  comment: ChecklistCardCommentType
}) => {
  const { users } = useRoomDetails()
  const { userId, createdAt, message } = comment

  const foundUser = users.find((elem) => elem.id === userId)

  return (
    <div className="comment-main-div">
      <div className="header">
        <div className="author-div">
          <Avatar picURL={foundUser?.picURL} name={foundUser?.name} />
          <Typography variant="subtitle1">{foundUser?.name ?? '--'}</Typography>
        </div>
        <Typography className="created-text" variant="subtitle2">
          {moment(createdAt).calendar()}
        </Typography>
      </div>
      <Typography className="message" variant="body2">
        {message}
      </Typography>
    </div>
  )
}

const ChecklistAdditionallyCard = ({
  userId,
  cardType,
  date
}: {
  userId: string
  cardType: string
  date: number
}) => {
  const { users } = useRoomDetails()
  const foundUser = users.find((elem) => elem.id === userId)

  return (
    <div className="additionally-card">
      <Avatar picURL={foundUser?.picURL} name={foundUser?.name} size={44} />
      <div>
        <Typography variant="subtitle1" className="user-name">
          {foundUser?.name ?? '--'}
        </Typography>
        <Typography variant="body2" className="subtitle">
          {`Added in ${CardTypeToLabel[cardType].replace(/in/g, '')} ${moment(
            date
          ).calendar()}`}
        </Typography>
      </div>
    </div>
  )
}

export const ChecklistCardDetails = ({
  card,
  open,
  setOpen
}: {
  card: ChecklistCardType
  open: boolean
  setOpen: Function
}) => {
  const {
    id: cardID,
    type,
    users,
    label,
    createdAt,
    lastUpdate,
    description,
    comments
  } = card
  const { roomName } = useParams()
  const updateChecklistCard = useUpdateChecklistCard()
  const createChecklistComment = useCreateChecklistComment()
  const [newUsers, setNewUsers] = useState<string[]>([])
  const [newLabel, setNewLabel] = useState<ChecklistCardLabelType | null>(null)
  const [newType, setNewType] = useState<'tasks' | 'inProgress' | 'done'>(type)
  const [newComment, setNewComment] = useState<string>('')

  const [addLabelAnchorEl, setAddLabelAnchorEl] =
    useState<HTMLButtonElement | null>(null)
  const [changeCardTypeAnchorEl, setChangeCardTypeAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const updateChecklistCardUsers = (newValue: string[]) => {
    setNewUsers(newValue)
    updateChecklistCard.mutate(
      {
        roomName,
        users: newValue,
        cardID: card.id
      },
      {
        onError: (error: any) => {
          // Go back to previous state
          toast.error(error?.data?.error?.message ?? error.message)
          setNewUsers(users)
        }
      }
    )
  }

  const updateChecklistCardLabel = (newValue: ChecklistCardLabelType) => {
    setNewLabel(newValue)
    updateChecklistCard.mutate(
      {
        roomName,
        label: newValue,
        cardID: card.id
      },
      {
        onError: (error: any) => {
          // Go back to previous state
          toast.error(error?.data?.error?.message ?? error.message)
          setNewLabel(label)
        }
      }
    )
  }

  const updateChecklistCardType = (newValue: string) => {
    setNewType(newValue as any)
    updateChecklistCard.mutate(
      {
        roomName,
        type: newValue,
        cardID: card.id
      },
      {
        onError: (error: any) => {
          // Go back to previous state
          toast.error(error?.data?.error?.message ?? error.message)
          setNewLabel(label)
        }
      }
    )
  }

  const onClickSendComment = () => {
    createChecklistComment.mutate(
      {
        roomName,
        cardID,
        comment: newComment
      },
      {
        onError: (error: any) => {
          // Go back to previous state
          toast.error(error?.data?.error?.message ?? error.message)
        },
        onSuccess: () => {
          setNewComment('')
        }
      }
    )
  }

  useEffect(() => {
    setNewUsers(users)
  }, [users])

  useEffect(() => {
    setNewLabel(label)
  }, [label])

  useEffect(() => {
    setNewType(type)
  }, [type])

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="checklist-card-details-div">
        <div className="header">
          <div className="label">
            <CardLabel
              label={newLabel?.name}
              color={newLabel?.color}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAddLabelAnchorEl(event.currentTarget)
              }
            />
            <Typography variant="body1">
              {moment(createdAt).format('MMM DD, YYYY')}
            </Typography>
          </div>
          <div className="header-text">
            <Typography variant="h6">{card.title}</Typography>
            <Typography variant="subtitle1">
              In list
              <button
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  setChangeCardTypeAnchorEl(event.currentTarget)
                }
              >
                <Typography variant="subtitle1">
                  {CardTypeToLabel[card.type]}
                </Typography>
              </button>
            </Typography>
          </div>
          <AddUsersButton
            userIds={newUsers}
            setUserIds={updateChecklistCardUsers}
          />
        </div>
        <div className="description-container">
          <Typography className="section-title" variant="subtitle1">
            Description
          </Typography>
          <div className="content">
            <Typography className="section-placeholder" variant="body1">
              {description?.length ? card.description : 'No Description'}
            </Typography>
          </div>
        </div>
        <Divider />
        <div className="comments-container">
          <Typography className="section-title" variant="subtitle1">
            Comments
          </Typography>
          <div className="content">
            {comments.length ? (
              comments.map((c, index) => (
                <ChecklistCardComment comment={c} key={`comment-${index}`} />
              ))
            ) : (
              <Typography className="section-placeholder" variant="body1">
                No comments
              </Typography>
            )}
          </div>
          <div className="comment-input-div">
            <TextField
              placeholder="Write your message..."
              variant="standard"
              size="small"
              className="chat-input-field"
              value={newComment}
              onChange={(event) => setNewComment(event.target.value)}
              onKeyPress={() => null}
              fullWidth
            />
            <AddEmojiButton
              onClickEmoji={(emoji) => setNewComment((prev) => prev + emoji)}
            />
            <Button
              label="Send"
              small
              disabled={!newComment.trim().length}
              onClick={onClickSendComment}
              isLoading={createChecklistComment.isLoading}
            />
          </div>
        </div>
        <div className="additionally-container">
          <Typography className="section-title" variant="subtitle1">
            Additionally
          </Typography>
          <ChecklistAdditionallyCard
            userId={lastUpdate?.userId}
            date={lastUpdate?.date}
            cardType={type}
          />
        </div>
        <AddLabelPopover
          anchorEl={addLabelAnchorEl}
          handleClose={() => setAddLabelAnchorEl(null)}
          checked={newLabel}
          setChecked={updateChecklistCardLabel}
          cardID={card.id}
        />
        <ChangeCardTypePopover
          anchorEl={changeCardTypeAnchorEl}
          handleClose={() => setChangeCardTypeAnchorEl(null)}
          type={newType}
          setType={updateChecklistCardType}
        />
      </div>
    </Modal>
  )
}
